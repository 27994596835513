import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import ErrorPage from '../../components/errorPage/ErrorPage';
import { INVALID_PASSWORD_TOKEN_PAGE } from '../../constants/amplitude/commonKeys';
import TokenError from '../../constants/TokenError';
import { FORGOT_PASSWORD } from '../../constants/urlPaths';
import { emailIcon } from '../../public/assets/icons';

const InvalidPasswordToken = ({ errorType }: { errorType: TokenError }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const isPageOpened = useRef(false);

    const localisedKey = (): string => {
        switch (errorType) {
            case TokenError.NotFound:
                return 'invalidPasswordToken';
            case TokenError.Expired:
                return 'expiredPasswordToken';
            default:
                throw new Error(`${errorType as string} is not a valid TokenError.`);
        }
    };

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(INVALID_PASSWORD_TOKEN_PAGE);
    }

    return (
        <ErrorPage
            icon={emailIcon}
            header={t(`errors.${localisedKey()}.description`)}
            description={t(`errors.${localisedKey()}.nextSteps`)}
            buttonTitle={t('buttonName.resetPassword')}
            onButtonClick={() => history.push(FORGOT_PASSWORD)}
        />
    );
};

export default InvalidPasswordToken;
