export const LANGUAGES = {
    SPANISH: {
        label: 'Spanish',
        value: 'es-ES',
    },
    ENGLISH: {
        label: 'English',
        value: 'en-GB',
    },
};
