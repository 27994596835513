export const DocumentUrlKey = 'url';

export interface DocumentItem {
    id: number;
    label: string;
    [DocumentUrlKey]: string;
}

export interface FileItem {
    id: number;
    label: string;
}

export interface DownloadDocumentParams {
    id: string;
    fileName: string;
    onSuccess: () => void;
    onError: () => void;
}
