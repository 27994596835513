/* eslint-disable max-len */
import { BUTTON, INPUT, SCREEN } from './eventSourceKeys';

export const SINGLE_RIDE_CONFIRM = `${BUTTON} Single Ride: Confirm ride`; // Start event
export const SINGLE_RIDE_CONFIRM_SAVE = `${BUTTON} Single Ride: Confirm ride: Save`; // End event

export const SINGLE_RIDE_CLOSE = `${BUTTON} Single Ride: Close ride`; // Start event
export const SINGLE_RIDE_CLOSE_SAVE = `${BUTTON} Single Ride: Close ride: Save`; // End event

export const SINGLE_RIDE_ADD_DRIVER = `${BUTTON} Single Ride: Add driver`; // Start event
export const SINGLE_RIDE_CHANGE_DRIVER = `${BUTTON} Single Ride: Change driver`; // Start event
export const SINGLE_RIDE_DRIVER_SAVE = `${BUTTON} Single Ride: Driver: Save`; // End event
export const SINGLE_RIDE_DRIVER_UNASSIGN = `${BUTTON} Single Ride: Unassign driver`; // End event
export const SINGLE_RIDE_DOWNLOAD_DOCUMENTATION = `${BUTTON} Single Ride: Download documentation`; // End event

export const SINGLE_RIDE_ADD_REMARKS = `${BUTTON} Single Ride: Add remarks`; // Start event
export const SINGLE_RIDE_ADD_REMARKS_SAVE = `${BUTTON} Single Ride: Add remarks: Save`; // End event
export const SINGLE_RIDE_ADD_REMARKS_BOOKING_REFERENCE = `${INPUT} Single Ride: Add remarks: Your booking reference(optional)`; // Middle event
export const SINGLE_RIDE_ADD_REMARKS_EXTRA_WAITING_TIME = `${INPUT} Single Ride: Add remarks: Additional costs: Extra waiting time`; // Middle event
export const SINGLE_RIDE_ADD_REMARKS_WAITING_TIME_COSTS = `${INPUT} Single Ride: Add remarks: Additional costs: Waiting time costs`; // Middle event
export const SINGLE_RIDE_ADD_REMARKS_PARKING_TOLL_COSTS = `${INPUT} Single Ride: Add remarks: Additional costs: Parking/toll costs`; // Middle event
export const SINGLE_RIDE_ADD_REMARKS_OTHER_COSTS = `${INPUT} Single Ride: Add remarks: Additional costs: Other costs`; // Middle event
export const SINGLE_RIDE_ADD_REMARKS_REMARKS = `${INPUT} Single Ride: Add remarks: Additional costs: Remarks`; // Middle event

export const ADD_DRIVER_AND_VEHICLE_BUTTON = `${BUTTON} Single Ride: Add driver & vehicle`; // Start event
export const CHANGE_DRIVER_AND_VEHICLE_BUTTON = `${BUTTON} Single Ride: Change driver & vehicle`; // Start event
export const VEHICLES_SCREEN = `${SCREEN} Vehicles`; // Start event
export const VEHICLES_CALENDAR_INPUT = `${INPUT} Vehicles: Calendar`; // Middle event
export const VEHICLES_SEARCH_INPUT = `${INPUT} Vehicles: Search`; // Middle event
export const NEW_VEHICLE_BUTTON = `${BUTTON} New vehicle`; // Start event
export const EDIT_VEHICLE_BUTTON = `${BUTTON} Edit vehicle`; // Start event
export const DUPLICATE_VEHICLE_BUTTON = `${BUTTON} Duplicate vehicle`; // Start event
export const DEACTIVATE_VEHICLE_BUTTON = `${BUTTON} Deactivate vehicle`; // Start event
export const ACTIVATE_VEHICLE_BUTTON = `${BUTTON} Activate vehicle`; // Start event
export const ADD_OR_EDIT_VEHICLE_LICENSE_PLATE_INPUT = `${INPUT} Add or edit vehicle: License plate`; // Middle event
export const ADD_OR_EDIT_VEHICLE_BRAND_INPUT = `${INPUT} Add or edit vehicle: Brand`; // Middle event
export const ADD_OR_EDIT_VEHICLE_MODEL_INPUT = `${INPUT} Add or edit vehicle: Model`; // Middle event
export const ADD_OR_EDIT_VEHICLE_COLOR_INPUT = `${INPUT} Add or edit vehicle: Color`; // Middle event
export const ADD_OR_EDIT_VEHICLE_IDENTIFIER_INPUT = `${INPUT} Add or edit vehicle: Identifier`; // Middle event
export const ADD_OR_EDIT_VEHICLE_PHOTO_INPUT = `${INPUT} Add or edit vehicle: Photo`; // Middle event
export const ADD_OR_EDIT_NEW_VEHICLE_REMOVE_PHOTO_BUTTON = `${BUTTON} Add or edit new vehicle: Remove photo`; // Middle event
export const ADD_OR_EDIT_NEW_VEHICLE_ADD_UPDATE_BUTTON = `${BUTTON} Add or edit new vehicle: Save`; // End event
export const DEACTIVATE_VEHICLE_DEACTIVATE_BUTTON = `${BUTTON} Deactivate vehicle: Deactivate`; // End event

export const DRIVERS_SCREEN = `${SCREEN} Drivers`; // Start event
export const NEW_DRIVER_BUTTON = `${BUTTON} New driver`; // Start event
export const EDIT_DRIVER_BUTTON = `${BUTTON} Edit driver`; // Start event
export const DEACTIVATE_DRIVER_BUTTON = `${BUTTON} Deactivate driver`; // Start event
export const ACTIVATE_DRIVER_BUTTON = `${BUTTON} Activate driver`; // Start event
export const ADD_OR_EDIT_DRIVER_NAME_SURNAME_INPUT = `${INPUT} Add or edit driver: Name and Surname`; // Middle event
export const ADD_OR_EDIT_DRIVER_PHONE_NUMBER_INPUT = `${INPUT} Add or edit driver: Phone number`; // Middle event
export const ADD_OR_EDIT_DRIVER_PHOTO_INPUT = `${INPUT} Add or edit driver: Photo`; // Middle event
export const ADD_OR_EDIT_DRIVER_REMOVE_PHOTO_INPUT = `${INPUT} Add or edit driver: Remove photo`; // Middle event
export const ADD_OR_EDIT_DRIVER_SAVE = `${BUTTON} Add or edit driver: Save`; // End event
