/* eslint-disable import/no-anonymous-default-export */
export default {
    appUrl: 'https://app.portal.get-e.com',
    logOutUrl: 'https://portal.get-e.com/portal/auth/logout',
    iframeUrl: 'https://portal.get-e.com/portal/views',
    signInUrl: 'https://portal.get-e.com/portal/login',
    bookingToolUrl: 'https://{subDomain}.book.get-e.com',
    forgottenPasswordUrl: 'https://portal.get-e.com/password/forget',
    setCookieUrl: 'https://cookiemonster.portal.get-e.com/authorize',
    refreshCookieUrl: 'https://cookiemonster.portal.get-e.com/refresh',
    deleteCookieUrl: 'https://cookiemonster.portal.get-e.com/log-out',
    googleAddressKey: 'AIzaSyCxPpCnxu55zcwoOeucuceKOqgXW-77NMg',
    apiUrl: 'https://portal.get-e.com/',
    useNewAuthentication: false,
    amplitudeApi: '32dd0337deaaf68cac0f6a4589365b5c',
};
