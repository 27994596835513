import { AxiosResponse } from 'axios';

import apiClient from '../../../services/api';
import { ACCEPT_INVITE } from '../../../services/routes';
import { AcceptUserInviteRequest } from './types';

export const acceptUserInvite = async (request: AcceptUserInviteRequest): Promise<AxiosResponse> => {
    const response = await apiClient.post<AcceptUserInviteRequest>(ACCEPT_INVITE, request);

    return response;
};
