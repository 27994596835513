export const DATE_FORMATS = {
    'DD MMM yyyy': 'DD MMM yyyy',
    'DD MMM': 'DD MMM',
    'ddd, DD MMM YYYY': 'ddd, DD MMM YYYY',
    'ddd DD MMM YYYY': 'ddd DD MMM YYYY',
    'ddd DD-MMM-YYYY HH:mm A': 'ddd DD-MMM-YYYY HH:mm A',
    'YYYY-MM-DDTHH:mm:ss': 'YYYY-MM-DDTHH:mm:ss',
    'ddd, DD MMM YYYY at HH:mm A': 'ddd, DD MMM YYYY [at] HH:mm A',
    'ddd DD MMM YYYY at HH:mm A': 'ddd DD MMM YYYY [at] HH:mm A',
    'DD MMM at HH:mm A': 'DD MMM [at] HH:mm A',
    'YYYY-MM-DD HH:mm:ss': 'YYYY-MM-DD HH:mm:ss',
    'YYYY/MM/DD HH:mm:ss': 'YYYY/MM/DD HH:mm:ss',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'dd MMM yyyy hh:mm a': 'dd MMM yyyy hh:mm a',
    'EEE, dd MMM yyyy at HH:mm a': "EEE, dd MMM yyyy 'at' HH:mm a",
    'dd MMM yyyy': 'dd MMM yyyy',
    'EEE, dd MMM yyyy': 'EEE, dd MMM yyyy',
    'MMM Do HH:mm A': 'MMM Do HH:mm A',
    MMM: 'MMM',
    MMMM: 'MMMM',
    'YYYY-MM-DDT': 'YYYY-MM-DDT',
    'd MMM yyyy at p': "d MMM yyyy 'at' p",
    'yyyy-MM-dd': 'yyyy-MM-dd',
    'DD MMM YYYY HH:mm': 'DD MMM YYYY HH:mm',
    'DD MMM YYYY': 'DD MMM YYYY',
    'ddd DD MMM': 'ddd DD MMM',
    'ddd Do MMM': 'ddd Do MMM',
    yyyy: 'yyyy',
    mmm: 'mmm',
    dd: 'dd',
    'YYYY-MM-DDT00:00:00': 'YYYY-MM-DDT00:00:00',
};
