/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, IconedData, ModalAction as Modal } from '@get-e/react-components';
import {
    Phone,
    Star,
    Badge,
    Face,
    DirectionsCar,
    Crop169,
    StarsOutlined,
    ColorLens,
    Bookmark,
    Add,
    InfoOutlined,
} from '@mui/icons-material';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { SINGLE_RIDE_DRIVER_UNASSIGN } from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import theme from '../../../../styles/theme';
import { addDriver, useDrivers } from '../../../drivers/api';
import { AddDriverRequest, Driver } from '../../../drivers/api/types';
import { unassignDriverFromRide } from '../../../rides/supplier/api';
import { useDriverVehicles, useVehicle, useVehicles } from '../../../vehicles/api';
import { Photo, Vehicle } from '../../../vehicles/api/types';
import { getColorOption } from '../../../vehicles/colorOptions';
import ColorCircle from '../../../vehicles/colorOptions/ColorCircle';
import AddVehicleModal from '../../../vehicles/components/AddVehicleModal';
import SmallVehicleImage from '../../../vehicles/components/SmallVehicleImage';
import { assignVehicleAndDriver } from '../../api';
import { RideContext } from '../context/RideContext';
import { ADD_DRIVER_ID, DEAFULT_ADD_DRIVER_BUTTON_OPTION } from '../hooks/useSupplierDriverInformation';
import AddDriverModal from './AddDriverModal';

const useStyles = makeStyles({
    driverVehicleModal: { '& .MuiDialog-container .MuiPaper-root': { maxWidth: '600px' } },
    formField: {
        marginBottom: '1.25rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    addNewContainer: {
        borderTop: `1px solid ${COLORS.DARK_GRAY}`,
        padding: '.75rem 1rem',
        cursor: 'pointer',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
    },
    addNew: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: COLORS.BLACK_50,
    },
    button: {
        marginRight: '1rem',
        border: `1px solid ${COLORS.BLUE}`,
        color: COLORS.BLUE,
        fontSize: '13px',
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
    driverOptionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: COLORS.SLATE_GREY,
        padding: '.75rem 1rem',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
        cursor: 'pointer',
    },
    entityInfo: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        flexWrap: 'wrap',
        padding: '1rem',
        height: 'auto',
    },
    entityImage: {
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '8px',
        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
        width: '88px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '88px',
        marginRight: '1rem',
        '& .MuiSvgIcon-root': {
            fontSize: '2.3rem',
            color: COLORS.DARK_GRAY,
        },
    },
    starIcon: {
        fontSize: '1.25rem',
        marginRight: '0.2rem',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
    },
    smallPhoto: {
        width: '30px',
        height: '30px',
        marginRight: '1rem',
    },
    vehicleOption: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '.75rem 1rem',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
    },
    notification: {
        padding: '1rem 1.3rem',
        backgroundColor: COLORS.LIGHT_BLUE_INFO,
        marginBottom: '2rem',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '.25rem',
        color: COLORS.WHITE,
    },
    infoIcon: {
        color: COLORS.WHITE,
        marginRight: '.7rem',
    },
});

interface DriverVehicleModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    initialVehicle: Vehicle | null;
    initialDriver: Driver | null;
    driverVehicleId?: number | null;
    isRidesOverview?: boolean;
    rideDataId?: string;
    rideStatus?: string;
}

const DriverVehicleModal = ({
    isModalOpen,
    initialDriver,
    initialVehicle,
    onClose,
    isRidesOverview,
    driverVehicleId,
    rideDataId,
    rideStatus,
}: DriverVehicleModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { showNotification } = useNotificationContext();
    const { rideId }: { rideId: string } = useParams();
    const { amplitudeEventProperties } = useContext(RideContext);

    const [driverSearchPhrase, setDriverSearchPhrase] = useState('');
    const [selectedDriver, setSelectedDriver] = useState<Driver | null | any>(initialDriver ? { ...initialDriver } : null);
    const [driverError, setDriverError] = useState('');
    const [vehicleSearchPhrase, setVehicleSearchPhrase] = useState('');
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null | any>(initialVehicle ? { ...initialVehicle } : null);
    const [addDriverModalOpen, setAddDriverModalOpen] = useState(false);
    const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
    const [initialVehicleStateSet, setInitialVehicleStateSet] = useState(false);

    const { data: drivers } = useDrivers(driverSearchPhrase);
    const { data: vehicles, refetch: refetchVehicles } = useVehicles(vehicleSearchPhrase, 500);
    const { data: driverVehicles = [] } = useDriverVehicles(selectedDriver?.id);
    const { data: vehicleData } = useVehicle(isRidesOverview ? driverVehicleId : null, '');

    const driverOptions: Driver[] = useMemo(() => drivers.filter(driver => !driver.isDeactivated), [drivers]);

    const vehicleOptions: Vehicle[] = useMemo(
        () =>
            vehicles
                .filter(vehicle => !vehicle.isArchived)
                .map(vcl => ({
                    ...vcl,
                    color: getColorOption(vcl.color || ''),
                })),
        [vehicles]
    );

    const driverVehicleOptions: Vehicle[] = useMemo(
        () => driverVehicles?.filter(vehicle => !vehicle.isArchived),
        [driverVehicles]
    );

    useEffect(() => {
        if (initialVehicle) {
            setSelectedVehicle(initialVehicle);
        }
    }, [initialVehicle]);

    useEffect(() => {
        if (initialDriver) {
            setSelectedDriver(initialDriver);
        }
    }, [initialDriver]);

    useEffect(() => {
        if (isRidesOverview && driverVehicleId && !initialVehicleStateSet && vehicleData) {
            setSelectedVehicle(vehicleData);
            setInitialVehicleStateSet(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleData]);

    const handleSubmit = async () => {
        if (!selectedDriver) {
            await handleUnassignDriver();
            return;
        }

        try {
            await assignVehicleAndDriver(isRidesOverview ? rideDataId || '' : rideId, {
                driverId: selectedDriver?.id,
                vehicleId: selectedVehicle ? selectedVehicle?.id : null,
            });

            showNotification(t('alert.edit.success'), Severity.Info);
        } catch (error: any) {
            showNotification(error?.response?.data?.message || t('errors.defaultError'), Severity.Error);
        } finally {
            onClose();
        }
    };

    const handleAddDriver = async (newDriver: AddDriverRequest) => {
        try {
            const response: any = await addDriver(newDriver);

            setSelectedDriver(response.data);

            showNotification(t('pages.drivers.driverSuccessfullyCreated'), Severity.Info);
        } catch (error: any) {
            showNotification(error?.response?.data?.message || t('errors.defaultError'), Severity.Error);
        } finally {
            setAddDriverModalOpen(false);
        }
    };

    const handleAddVehicle = async (id: number) => {
        const refetchedVehicles = await refetchVehicles();
        const vehicle = refetchedVehicles.data?.find(veh => veh.id === id);

        if (vehicle) {
            setSelectedVehicle(vehicle);
        }

        setAddVehicleModalOpen(false);
    };

    const handleUnassignDriver = async () => {
        try {
            await unassignDriverFromRide(isRidesOverview ? rideDataId || '' : rideId);
            showNotification(t('alert.edit.successRemoveDriver'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_DRIVER_UNASSIGN, amplitudeEventProperties);
            onClose?.();
        } catch (error) {
            showNotification(t('alert.driverUnassignError'), Severity.Error);
        }
    };

    return (
        <Modal
            modalContentClassName={classes.driverVehicleModal}
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('modals.driverAndVehicle.title')}
            confirmButtonLabel={t('buttonName.confirm')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={false}
            maxWidth="lg"
            fullWidth
        >
            <Grid>
                <Grid item>
                    {selectedDriver && selectedVehicle && rideStatus === 'TO_CONFIRM' && (
                        <Box className={classes.notification} style={{ width: isMobile ? '87%' : '92%' }}>
                            <InfoOutlined fontSize="small" className={classes.infoIcon} />
                            <Typography fontWeight={700}>{t('modals.driverAndVehicle.autoConfirmWarning')}</Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <Autocomplete
                        label={t('pages.drivers.searchByNameOrPhoneNumber')}
                        options={driverOptions}
                        getOptionLabel={(option: Driver) => option.name}
                        getOptionKey={(option: Driver) => option.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        filterOptions={(options: Driver[]) => {
                            const found = options.some(el => el.id === ADD_DRIVER_ID);

                            if (!found) {
                                options.push(DEAFULT_ADD_DRIVER_BUTTON_OPTION);
                            }

                            return options;
                        }}
                        renderOption={(prop, option: Driver) => {
                            const averageRate = option.feedbacks?.averageRate
                                ? option.feedbacks?.averageRate.toFixed(1).toString()
                                : '';

                            const count = option.feedbacks?.count ?? '';

                            if (option.id === ADD_DRIVER_ID) {
                                return (
                                    <Box
                                        key={option.id}
                                        component="li"
                                        className={classes.addNewContainer}
                                        onClick={() => {
                                            setAddDriverModalOpen(true);
                                            setSelectedDriver(null);
                                        }}
                                    >
                                        <Grid className={classes.addNew}>
                                            <>{t('buttonName.addNew')}</>
                                            <Add />
                                        </Grid>
                                    </Box>
                                );
                            }

                            return (
                                <Box key={option.id} component="li" {...prop} className={classes.driverOptionContainer}>
                                    <Box className={classes.smallPhoto}>
                                        <SmallVehicleImage photo={option.pictureUrl || ''} boxSize="36px" />
                                    </Box>
                                    <Typography marginRight="0.2rem" marginTop="0.5rem" color={COLORS.BLACK}>
                                        {option.name} ({option.phoneNumber})
                                    </Typography>
                                    <Typography marginRight="0.2rem" marginTop="0.5rem">
                                        {averageRate}
                                    </Typography>
                                    {count !== '' && count !== 0 && (
                                        <>
                                            <Star className={classes.starIcon} />
                                            <Typography>({option.feedbacks?.count})</Typography>
                                        </>
                                    )}
                                </Box>
                            );
                        }}
                        value={selectedDriver}
                        onChange={(_, newValue) => {
                            if (!newValue) {
                                setSelectedVehicle(null);
                            }

                            setSelectedDriver(newValue as Driver);
                            setDriverError('');
                        }}
                        required
                        className={classes.formField}
                        inputValue={driverSearchPhrase}
                        onInputChange={(_, newInputValue) => {
                            setDriverSearchPhrase(newInputValue);
                            setDriverError('');
                        }}
                        isError={driverError !== ''}
                        helperText={driverError ? t(driverError) : ''}
                    />
                    <Box className={classes.entityInfo}>
                        <Grid className={classes.entityImage}>
                            {!selectedDriver || !selectedDriver?.pictureUrl ? (
                                <Face />
                            ) : (
                                <img className={classes.image} src={selectedDriver?.pictureUrl} alt="driver" />
                            )}
                        </Grid>
                        <Box>
                            <IconedData
                                dataTextBold
                                icon={Badge}
                                dataLabel={t('pages.singleRide.driverInformation.driver')}
                                data={selectedDriver?.name || '-'}
                                dataLabelColor={COLORS.BLACK}
                                isColumn
                                topMargin="0.4rem"
                                fontSize=".75rem"
                                fontTextSize="1rem"
                                dataLabelBold={false}
                                iconSize="1.25rem"
                            />
                            <IconedData
                                dataTextBold
                                icon={Phone}
                                dataLabel={t('pages.singleRide.driverInformation.phone')}
                                data={selectedDriver?.phoneNumber || '-'}
                                dataLabelColor={COLORS.BLACK}
                                isColumn
                                topMargin="0.5rem"
                                fontSize=".75rem"
                                fontTextSize="1rem"
                                dataLabelBold={false}
                                iconSize="1.25rem"
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item marginTop="2rem">
                    <Autocomplete
                        disabled={!selectedDriver}
                        label={t('pages.vehicles.search')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        componentsProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'flip',
                                        enabled: false,
                                    },
                                    {
                                        name: 'preventOverflow',
                                        enabled: false,
                                    },
                                ],
                            },
                        }}
                        options={
                            !vehicleSearchPhrase && driverVehicleOptions.length > 0 && selectedDriver
                                ? driverVehicleOptions
                                : vehicleOptions
                        }
                        getOptionLabel={(option: Vehicle) => {
                            if (option.id === -1) {
                                return '';
                            }

                            return `${option.licensePlate} ${option.brand?.name || ''} ${option.model?.name || ''}`;
                        }}
                        filterOptions={(options: Vehicle[]) => {
                            const found = options.some(el => el.id === -1);

                            if (!found) {
                                options.push({
                                    id: -1,
                                    licensePlate: '',
                                    identifier: '',
                                    brand: {
                                        name: '',
                                        id: -1,
                                    },
                                    color: '',
                                    model: {
                                        name: '',
                                        id: -1,
                                    },
                                    status: '',
                                    pictures: [],
                                    isArchived: false,
                                });
                            }

                            return options;
                        }}
                        renderOption={(prop, option: Vehicle) => {
                            if (option.id === -1) {
                                return (
                                    <Box
                                        key={option.id}
                                        component="li"
                                        className={classes.addNewContainer}
                                        onClick={() => {
                                            setAddVehicleModalOpen(true);
                                            setSelectedVehicle(null);
                                        }}
                                    >
                                        <Grid className={classes.addNew}>
                                            <>{t('buttonName.addNew')}</>
                                            <>
                                                <Add />
                                            </>
                                        </Grid>
                                    </Box>
                                );
                            }

                            const photo = option.pictures.find(picture => picture.isDefault) || option.pictures[0];

                            return (
                                <Box {...prop} component="li" className={classes.vehicleOption} key={option.id}>
                                    <Box className={classes.smallPhoto}>
                                        <SmallVehicleImage photo={photo?.url} boxSize="36px" />
                                    </Box>
                                    <Typography marginRight="0.5rem" marginTop="0.5rem">
                                        {option.licensePlate}
                                    </Typography>
                                    <Typography color={COLORS.SLATE_GREY} marginTop="0.5rem">
                                        {option.brand?.name || ''} {option.model?.name || ''}
                                    </Typography>
                                </Box>
                            );
                        }}
                        value={selectedVehicle}
                        onChange={(_, newValue) => {
                            setSelectedVehicle(newValue as Vehicle);
                        }}
                        className={classes.formField}
                        inputValue={vehicleSearchPhrase}
                        onInputChange={(_, newInputValue) => {
                            setVehicleSearchPhrase(newInputValue);
                        }}
                    />
                    <Box className={classes.entityInfo}>
                        <Grid className={classes.entityImage}>
                            {!selectedVehicle || !selectedVehicle.pictures || selectedVehicle?.pictures?.length === 0 ? (
                                <DirectionsCar />
                            ) : (
                                <img
                                    className={classes.image}
                                    src={
                                        selectedVehicle?.pictures?.find((pic: Photo) => pic.isDefault)?.url ||
                                        selectedVehicle?.pictures[0].url
                                    }
                                    alt="vehicle"
                                />
                            )}
                        </Grid>
                        <Grid maxWidth="85%">
                            <Box
                                display="flex"
                                flexDirection={isMobile ? 'column' : 'row'}
                                justifyContent="space-between"
                                alignItems={isMobile ? 'flex-start' : 'center'}
                            >
                                <IconedData
                                    minWidth="157px"
                                    dataTextBold
                                    icon={Crop169}
                                    dataLabel={t('licensePlate')}
                                    data={selectedVehicle?.licensePlate || '-'}
                                    dataLabelColor={COLORS.BLACK}
                                    isColumn
                                    iconRightMargin="0.625rem"
                                    topMargin="0.4rem"
                                    fontSize=".75rem"
                                    fontTextSize="1rem"
                                    dataLabelBold={false}
                                    iconSize="1.25rem"
                                />
                                <IconedData
                                    minWidth="157px"
                                    dataTextBold
                                    icon={StarsOutlined}
                                    dataLabel={t('brand&model')}
                                    data={
                                        selectedVehicle
                                            ? (selectedVehicle.brand?.name || '') +
                                                  (selectedVehicle.brand?.name && selectedVehicle.model?.name ? ' ' : '') +
                                                  (selectedVehicle.model?.name || '') || '-'
                                            : '-'
                                    }
                                    dataLabelColor={COLORS.BLACK}
                                    isColumn
                                    topMargin="0.5rem"
                                    fontSize=".75rem"
                                    fontTextSize="1rem"
                                    dataLabelBold={false}
                                    iconSize="1.25rem"
                                />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection={isMobile ? 'column' : 'row'}
                                justifyContent="space-between"
                                marginTop="0.5rem"
                                alignItems={isMobile ? 'flex-start' : 'center'}
                            >
                                <IconedData
                                    minWidth="157px"
                                    dataTextBold
                                    icon={ColorLens}
                                    dataLabel={t('color')}
                                    fontSize=".75rem"
                                    fontTextSize="1rem"
                                    dataLabelBold={false}
                                    iconSize="1.25rem"
                                    data={
                                        selectedVehicle ? (
                                            <ColorCircle option={selectedVehicle?.color} paddingBox="0" textBold />
                                        ) : (
                                            '-'
                                        )
                                    }
                                    dataLabelColor={COLORS.BLACK}
                                    isColumn
                                />
                                <IconedData
                                    minWidth="157px"
                                    dataTextBold
                                    icon={Bookmark}
                                    dataLabel={t('identifier')}
                                    data={selectedVehicle?.identifier || '-'}
                                    dataLabelColor={COLORS.BLACK}
                                    isColumn
                                    topMargin={isMobile ? '0.5rem' : '0'}
                                    fontSize=".75rem"
                                    fontTextSize="1rem"
                                    dataLabelBold={false}
                                    iconSize="1.25rem"
                                />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            {addDriverModalOpen && (
                <AddDriverModal
                    onAddDriver={handleAddDriver}
                    isModalOpen={addDriverModalOpen}
                    onClose={() => setAddDriverModalOpen(false)}
                />
            )}
            {addVehicleModalOpen && (
                <AddVehicleModal
                    onSuccess={handleAddVehicle}
                    isOpen={addVehicleModalOpen}
                    onClose={() => setAddVehicleModalOpen(false)}
                />
            )}
        </Modal>
    );
};

export default DriverVehicleModal;
