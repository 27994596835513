import { SvgIconComponent } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import { CustomWidthTooltip } from './CustomWidthTooltip';

export interface DropdownMenuOption {
    id: number;
    label: string;
    onClick?: () => void;
    icon?: SvgIconComponent;
    labelColor?: string;
    iconColor?: string;
    iconSize?: string;
    tooltip?: string;
}

interface DropdownButtonMenuProps {
    options: DropdownMenuOption[];
    buttonLabel: string;
    buttonIcon?: ReactNode;
    onClick?: () => void;
    buttonHeight?: string;
    tooltip?: string;
    tooltipWidth?: number;
    width?: string;
    isDisabled?: boolean;
    fontSize?: string;
    buttonClassName?: string;
}

const DropdownButtonMenu = ({
    options,
    buttonLabel,
    buttonIcon,
    onClick,
    buttonHeight,
    tooltip,
    tooltipWidth,
    width,
    isDisabled,
    fontSize,
    buttonClassName,
}: DropdownButtonMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onClick?.();
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: DropdownMenuOption) => {
        option.onClick && option.onClick();
        handleClose();
    };

    return (
        <>
            <Box display="flex" sx={{ textWrap: 'nowrap' }}>
                <CustomWidthTooltip title={tooltip} hidden={!tooltip} placement="top" arrow maxWidth={tooltipWidth}>
                    <Button
                        variant="outlined"
                        onClick={handleButtonClick}
                        disabled={isDisabled}
                        className={buttonClassName}
                        style={{
                            width: width ?? '100%',
                            height: buttonHeight ?? '36px',
                            fontSize: fontSize ?? '0.875rem',
                            borderTopRightRadius: options.length > 0 ? 0 : '4px',
                            borderBottomRightRadius: options.length > 0 ? 0 : '4px',
                            borderColor: COLORS.BLUE,
                        }}
                        startIcon={buttonIcon ?? undefined}
                    >
                        {t(buttonLabel)}
                    </Button>
                </CustomWidthTooltip>
                {options.length > 0 && (
                    <Button
                        variant="outlined"
                        onClick={handleMenuClick}
                        disabled={isDisabled}
                        className={buttonClassName}
                        style={{
                            width: '40px',
                            maxWidth: '40px',
                            minWidth: '40px',
                            fontSize: fontSize ?? '0.75rem',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: '0',
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderColor: COLORS.BLUE,
                        }}
                        startIcon={<KeyboardArrowDownIcon style={{ marginLeft: '0.75rem' }} />}
                    />
                )}
            </Box>
            {options.length > 0 && (
                <Menu
                    id="long-menu"
                    MenuListProps={{ 'aria-labelledby': 'long-button' }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            transform: 'translateX(40px)',
                            width: 'auto',
                        },
                    }}
                >
                    {options.map(option => (
                        <MenuItem
                            key={option.id}
                            disabled={isDisabled}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleOptionClick(option);
                            }}
                            sx={{
                                padding: '.75rem 1rem',
                                '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
                            }}
                        >
                            {option.icon && (
                                <ListItemIcon>
                                    <SvgIcon
                                        component={option.icon}
                                        style={{
                                            color: option.iconColor ?? COLORS.BLACK,
                                            marginRight: '0.625rem',
                                            fontSize: option.iconSize,
                                        }}
                                    />
                                </ListItemIcon>
                            )}
                            <ListItemText color={option.labelColor ?? COLORS.BLACK}>{option.label}</ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export default DropdownButtonMenu;
