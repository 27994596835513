import FormError from './FormError';

export default function getFormErrorMessage(error: FormError): string {
    switch (error) {
        case FormError.Unauthorized:
            return 'errors.formWError.unauthorized';
        case FormError.UnexpectedError:
            return 'errors.formWError.unexpectedError';
        case FormError.WrongCredentials:
            return 'errors.formError.wrongCredentials';
        default:
            throw new Error('Unhandled form error');
    }
}
