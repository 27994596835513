export default function secondsToTimeFormatted(seconds: number | undefined) {
    if (!seconds) {
        return '';
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const hDisplay = hours > 0 ? `${hours} H ` : '';
    const mDisplay = minutes > 0 ? `${minutes} min` : '';

    return hDisplay + mDisplay;
}
