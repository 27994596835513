import React from 'react';
import Lightbox from 'react-awesome-lightbox';
import ReactDOM from 'react-dom';
import 'react-awesome-lightbox/build/style.css';

interface LightBoxPortalProps {
    open: boolean;
    onClose: (evt?) => void;
    images?: string[] | undefined;
    startIndex?: number;
}

const LightBoxPortal = ({ open, onClose, images = [], startIndex }: LightBoxPortalProps) => {
    if (!open) {
        return null;
    }

    return ReactDOM.createPortal(
        <Lightbox
            image={images?.[0]}
            images={images.length > 1 ? images : undefined}
            onClose={onClose}
            startIndex={startIndex}
        />,
        document.body
    );
};

export default LightBoxPortal;
