export interface ReportParams {
    startDate: string;
    endDate: string;
    accountId: number;
    accountType?: string;
    queryKey?: string;
}

export interface SummaryResponse {
    totalRides: number;
}

interface DriversRide {
    tripId: number;
    tripUnid: string;
    pickupTimeLocal: string;
    driverId: number;
    driverName: string;
    closestDistanceToArrivalPointInMeters?: number;
    closestDistanceToDeparturePointInMeters?: number;
    timeDifferenceBetweenFirstAndLastStatusUpdate?: number;
}

interface DriversSummary {
    totalTripsWithDriversAdded: number;
    totalAccuratelyTrackedRides: number;
}

export interface DriversResponse {
    summary: DriversSummary;
    drivers?: Array<{
        driver: Partial<Driver> & { id: number; name: string };
        totalTrips: number;
        totalReviews: number;
        averageRate: number | null;
        rides: DriversRide[];
    }>;
}

export interface SituationsResponse {
    summary: {
        totalSituations: number;
        totalSituationsByType: SummarySituations;
    };
    situations: Array<{
        pickupTime: string;
        rideNumber: string;
        driver: Partial<Driver> | null;
        situationType: string | null;
        situations: Array<{
            id: number;
            code: string;
        }>;
    }>;
}

interface Topic {
    topic: string;
    rate: number | null;
}

interface ReviewRide {
    rideNumber: string;
    pickUpTime: Date;
    driver: string;
}

export interface Review {
    id: number;
    ride: ReviewRide;
    averageRate: number;
    hidden: boolean;
    adminComment: string | null;
    topics: Topic[] | null;
    comments: string[];
}

interface SummaryReview {
    totalReviews: number;
    totalRate: number;
    totalOneStar: number;
    totalTwoStar: number;
    totalThreeStar: number;
    totalFourStar: number;
    totalFiveStar: number;
    averageRateByTopic: Record<string, number>;
}

export interface ReviewsResponse {
    summary: SummaryReview;
    reviews: Review[];
}

interface SummarySituations {
    totalSituations: number;
    totalDriverLate: number;
    totalOnRouteDelay: number;
    totalDriverNoShow: number;
    totalVehicleCondition: number;
    totalServiceQuality: number;
    totalSafetyIncident: number;
    totalWrongVehicle: number;
    totalOther: number;
}

interface Summary {
    totalRides: number;
    totalTripsWithDriversAdded: number;
    totalAccuratelyTrackedRides: number;
    situations: SummarySituations;
    reviews: SummaryReview;
}

export interface QualityReport {
    summary: Summary;
    situations: Situation[];
    drivers: Driver[];
    reviews: Review[];
}

export interface Situation {
    pickupTime: Date;
    rideNumber: string;
    driver: string;
    situationType: SituationType;
}

export interface DriverRide {
    pickupTime: Date;
    rideNumber: string;
    gpsTracking: QualityValuationEnum;
    driverUpdates: QualityValuationEnum;
}

export interface Driver {
    id: number;
    name: string;
    totalTrips: number;
    totalReviews: number;
    averageRate: number | null;
    rides: DriverRide[];
}

export enum SituationType {
    DriverLate = 'DRIVER_LATE',
    DriverNoShow = 'DRIVER_NO_SHOW',
    WrongVehicle = 'WRONG_VEHICLE',
    SafetyIncident = 'SAFETY_INCIDENT',
    VehicleCondition = 'VEHICLE_CONDITION',
    ServiceQuality = 'SERVICE_QUALITY',
    OnRouteDelay = 'ON_ROUTE_DELAY',
    Other = 'OTHER',
}

export enum QualityValuationEnum {
    ACCURATE = 'Accurate',
    NOT_ACCURATE = 'Not accurate',
    NO_UPDATES = 'No updates',
}

export interface ReportTabProps {
    currentPeriod?: QualityReport;
    pastPeriod?: QualityReport;
    period: string;
}
