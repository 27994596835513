import { createContext } from 'react';

import { RideAmplitudeEventProperties, RideResponse } from '../../api/types';

export interface SupplierRideContext {
    isDisabled: boolean;
    rideResponse?: RideResponse;
    amplitudeEventProperties?: RideAmplitudeEventProperties;
}

export const RideContext = createContext<SupplierRideContext>({
    rideResponse: undefined,
    isDisabled: false,
});
