import { RemarksValues } from '../../../rides/supplier/components/RemarksCard';
import { ConfirmCloseAddRemarksRideRequest } from '../../api/types';

export const remarksValuesToConfirmCloseAddRemarksRideRequest = (
    reference: string | null,
    remarksValues: RemarksValues
): ConfirmCloseAddRemarksRideRequest => {
    const request = {} as ConfirmCloseAddRemarksRideRequest;

    if (reference) {
        request.referenceNumber = reference;
    }

    if (remarksValues.comments) {
        request.remarks = remarksValues.comments;
    }

    if (remarksValues.extraWaitingTime) {
        request.minutesWaited = parseFloat(remarksValues.extraWaitingTime);
    }

    if (remarksValues.waitingTimeCosts) {
        request.minutesCosts = parseFloat(remarksValues.waitingTimeCosts);
    }

    if (remarksValues.parkingTollCosts) {
        request.parkingCosts = parseFloat(remarksValues.parkingTollCosts);
    }

    if (remarksValues.otherCosts) {
        request.otherCosts = parseFloat(remarksValues.otherCosts);
    }

    return request;
};
