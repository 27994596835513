import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { emptyAirline } from '../../public/assets/images';
import theme from '../../styles/theme';

export const CustomNoRowsOverlay = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '400px',
            }}
        >
            <img
                src={emptyAirline}
                alt={t('noResultsFound')}
                style={{
                    paddingBottom: '2rem',
                    width: isMobile ? '300px' : 'auto',
                }}
            />
            {t('noResultsFound')}
        </div>
    );
};
