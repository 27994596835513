export const COLORS = {
    BLACK: '#1A364C',
    BLACK_50: 'rgba(0, 0, 0, 0.5)',
    BLACK_12: 'rgba(0, 0, 0, 0.12)',
    WHITE: '#FFFFFF',
    WHITE_10: 'rgba(255,255,255,0.1)',
    WHITE_20: 'rgba(255,255,255,0.2)',
    SLATE_GREY: '#788B9B',
    DARK_GRAY: '#BCBDCB',
    LIGHT_GRAY: '#EEF3F9',
    EXTRA_LIGHT_GRAY: '#F8F8F8',
    LIGHT_BLUE_INFO: '#03A9F4',
    BLUE_DARK: '#001154',
    BLUE: '#073690',
    LIGHT_BLUE: '#BAC3D8',
    ORANGE: '#F86909',
    ORANGE_OFFICIAL: '#FF9600',
    ORANGE_WARNING: '#E99A00',
    LIGHT_ORANGE: '#FFEBD7',
    MEDIUM_ORANGE: '#FFE3C7',
    GREEN: '#12944A',
    LIGHT_GREEN: '#BDDDCB',
    RED: '#B72323',
    RED_WARNING: '#801818',
    LIGHT_RED: '#E6C1C1',
    ORANGE_TEXT: '#BE3800',
    BLUE_TEXT: '#082E82',
    GREEN_TEXT: '#006B2E',
    GREY_INPUT: '#EFEFEF',
    PROFILE_COLORS: {
        PASTEL_ORANGE: '#FFC09C',
        PASTEL_PINKISH: '#FF9C9C',
        PASTEL_YELLOWISH: '#FFE99C',
        PASTEL_MARKER_GREEN: '#9EFF9C',
        PASTEL_CYAN: '#9CEDFF',
        PASTEL_BLUE: '#9CB8FF',
        PASTEL_VIOLENT: '#BC9CFF',
        PASTEL_VERY_PINK: '#FF9CFB',
    },
};

export function getRandomProfileColor(): string {
    const colorKeys = Object.keys(COLORS.PROFILE_COLORS);
    const randomColorKey = colorKeys[Math.floor(Math.random() * colorKeys.length)];

    return COLORS.PROFILE_COLORS[randomColorKey] as string;
}
