export const colorOptions = [
    /*
     * {
     *     id: 'UNSPECIFIED',
     *     color: 'UNSPECIFIED',
     *     name: 'colors.unspecified',
     * },
     */
    {
        id: 'WHITE',
        color: '#FFFFFF',
        name: 'colors.white',
    },
    {
        id: 'BLACK',
        color: '#000000',
        name: 'colors.black',
    },
    {
        id: 'SILVER_GRAY',
        color: '#A1A5B2',
        name: 'colors.silverGray',
    },
    {
        id: 'YELLOW',
        color: '#FCCF43',
        name: 'colors.yellow',
    },
    {
        id: 'LIGHT_BLUE',
        color: '#134DE1',
        name: 'colors.lightBlue',
    },
    {
        id: 'DARK_BLUE',
        color: '#001154',
        name: 'colors.darkBlue',
    },
    {
        id: 'LIGHT_BROWN',
        color: '#C9A58B',
        name: 'colors.lightBrown',
    },
    {
        id: 'DARK_BROWN',
        color: '#783F17',
        name: 'colors.darkBrown',
    },
    {
        id: 'GREEN',
        color: '#12944A',
        name: 'colors.green',
    },
    {
        id: 'ORANGE',
        color: '#FF7F00',
        name: 'colors.orange',
    },
    {
        id: 'PINK',
        color: '#FF9CFB',
        name: 'colors.pink',
    },
    {
        id: 'PURPLE',
        color: '#8613E1',
        name: 'colors.purple',
    },
    {
        id: 'RED',
        color: '#B72323',
        name: 'colors.red',
    },
];

export interface ColorOption {
    id: string;
    color?: string;
    name: string;
}

export const getColorOption = (colorId: string | ColorOption) => colorOptions.find(el => el.id === colorId) || colorOptions[0];
