import { createContext } from 'react';

import { Point } from '../../../../components/navigation/GoogleMap';
import { Coordinates, PrimaryTimeZone } from '../../../../services/types';
import { Booker, RideAmplitudeEventProperties } from '../../api/types';

export interface RideMapType {
    pickupCoordinates?: Coordinates;
    dropOffCoordinates?: Coordinates;
    pickUpPlaceId?: string;
    dropOffPlaceId?: string;
    lastPositionUpdate?: Point;
    prettifiedUnid: string;
}

export interface SummaryRideType {
    prettifiedUnid: string;
    status: string;
    distance: string;
    time: string;
    pickupCoordinates?: Coordinates;
    dropOffCoordinates?: Coordinates;
    paymentMethod?: string;
    booker?: Booker;
    primaryTimeZone: PrimaryTimeZone | null;
    scheduledArrival: string;
    scheduledArrivalLocal: string;
    pickUpPlaceId?: string;
    dropOffPlaceId?: string;
    specialRequest?: string;
    vehicleType?: string;
    price?: string;
    mainTraveller?: string;
    otherTraveller?: string;
    lastPositionUpdate?: Point;
}

export interface RideContextType {
    summary: SummaryRideType;
    isDisabled: boolean;
    addedTravellerCount: number;
    isTravellerIncreasingDisabled: boolean;
    amplitudeEventProperties?: RideAmplitudeEventProperties;
}

export const RideContext = createContext<RideContextType>({
    summary: {
        prettifiedUnid: '',
        status: '',
        distance: '',
        time: '',
        pickupCoordinates: undefined,
        dropOffCoordinates: undefined,
        primaryTimeZone: PrimaryTimeZone.LOCAL,
        scheduledArrival: '',
        scheduledArrivalLocal: '',
        lastPositionUpdate: undefined,
    },
    isDisabled: false,
    addedTravellerCount: 0,
    isTravellerIncreasingDisabled: false,
    amplitudeEventProperties: {
        'Ride number': '',
        'Ride created time': '',
        'Ride pickup time': '',
        'Ride customer': '',
        'Ride supplier': '',
        'Ride status': '',
        'Ride situation tags': '',
        'Ride qc status': false,
        'Ride has driver': false,
    },
});

export interface NewRideContextType {
    summary: RideMapType;
    amplitudeEventProperties?: RideAmplitudeEventProperties;
}

export const NewRideContext = createContext<NewRideContextType>({
    summary: {
        prettifiedUnid: '',
        pickupCoordinates: undefined,
        dropOffCoordinates: undefined,
        lastPositionUpdate: undefined,
        pickUpPlaceId: '',
        dropOffPlaceId: '',
    },
    amplitudeEventProperties: {
        'Ride number': '',
        'Ride created time': '',
        'Ride pickup time': '',
        'Ride customer': '',
        'Ride supplier': '',
        'Ride status': '',
        'Ride situation tags': '',
        'Ride qc status': false,
        'Ride has driver': false,
    },
});
