enum InputError {
    LowerCaseRequired = 'form.helperText.lowerCaseRequired',
    UpperCaseRequired = 'form.helperText.upperCaseRequired',
    NumberRequired = 'form.helperText.numberRequired',
    SpecialCharacterRequired = 'form.helperText.specialCharacterRequired',
    OnlyLettersAndNumbersAllowed = 'form.helperText.onlyLettersAndNumbersAllowed',
    MaxLengthExceeded = 'form.helperText.maxLengthExceeded',
    ContainsWhiteSpace = 'form.helperText.noWhiteSpace',
    Empty = 'form.helperText.empty',
    TooLong = 'errors.tooLong',
    InvalidEmail = 'errors.invalidEmail',
    EmptyEmail = 'errors.emptyEmail',
    InvalidDate = 'errors.invalidDate',
    InvalidPastDate = 'errors.invalidPastDate',
    InvalidPastTime = 'errors.invalidPastTime',
    InvalidDateFormat = 'errors.invalidDateFormat',
    InvalidTimeFormat = 'errors.invalidTimeFormat',
    InvalidAfterDate = 'errors.invalidAfterDate',
    InvalidAfterTime = 'errors.invalidAfterTime',
    NotStrongPassword = 'errors.notStrongPassword',
    NoMatch = 'errors.noMatch',
    InvalidLogin = 'errors.invalidLogin',
    EmptyLoginPassword = 'errors.emptyLoginPassword',
    EmptyLoginEmail = 'errors.emptyLoginEmail',
    EmailAlreadyTaken = 'errors.emailAlreadyTaken',
    LicensePlateAlreadyTaken = 'errors.licensePlateAlreadyTaken',
}

enum PasswordError {
    TooShort = 'passwordTooShort',
}

enum ConfirmPasswordError {
    MustMatch = 'form.helperText.passwordsMustMatch',
}

export { InputError, ConfirmPasswordError, PasswordError };
