export default function metersToKmAndMiFormatted(meters: number | undefined) {
    if (!meters) {
        return '';
    }

    const kilometers = (meters / 1000).toFixed(1);
    const miles = (meters * 0.000621371192).toFixed(1);

    return `${kilometers} km / ${miles} mi`;
}
