import { Tooltip, Typography } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';

interface CellTextProps {
    children: ReactNode;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    fontSize?: string;
    tooltip?: string | string[];
}

const CellText = ({ children, marginTop, marginBottom, marginLeft, fontSize, tooltip }: CellTextProps) => {
    const tooltipContent = useMemo(() => {
        if (Array.isArray(tooltip)) {
            return tooltip.map(value => (
                <Typography key={value} fontSize="0.75rem" lineHeight="0.95rem" fontWeight="700">
                    {value}
                </Typography>
            ));
        } else if (typeof tooltip === 'string') {
            return tooltip;
        }

        return '';
    }, [tooltip]);

    const tooltipText = useMemo(
        () => (typeof tooltipContent === 'string' ? tooltipContent : <>{tooltipContent}</>),
        [tooltipContent]
    );

    return (
        <Tooltip title={tooltipText} placement="top" arrow>
            <Typography
                style={{
                    cursor: tooltip ? 'pointer' : 'initial',
                    overflow: 'hidden',
                }}
                fontSize={fontSize ? `${fontSize} !important` : '0.75rem'}
                lineHeight={fontSize ? 'initial' : '0.875rem'}
                marginTop={marginTop ? `${marginTop} !important` : 0}
                marginBottom={marginBottom ? `${marginBottom} !important` : 0}
                marginLeft={marginLeft ? `${marginLeft} !important` : 0}
            >
                {children}
            </Typography>
        </Tooltip>
    );
};

export default CellText;
