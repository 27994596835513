import { getSingleRideRoute } from '../../../../constants/urlPaths';

export const handleRideMiddleMouseClick = (event: MouseEvent) => {
    if (event.button === 1) {
        const path = event.composedPath();
        const row = path.find(el => el instanceof HTMLElement && el.getAttribute('role') === 'row');

        if (row instanceof HTMLElement) {
            const rideId = row.getAttribute('data-id');

            if (rideId) {
                window.open(getSingleRideRoute(rideId), '_blank');
            }
        }
    }
};
