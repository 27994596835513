import { IconedData, ModalAction as Modal } from '@get-e/react-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button, Grid, Typography, Link, Box, IconButton } from '@mui/material';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import {
    EUROPE_PRIORITY,
    GLOBAL_PRIORITY,
    NL_OPERATIONS,
    UK_GLOBAL_OPERATIONS,
    WEB_WHATSAPP_REDIRECTING,
} from '../../../constants/contacts';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { openZendeskLiveChat } from '../../../helpers/zendeskConfig';
import useButtonStyles from '../../../styles/Button';
import typographyStyles from '../../../styles/Typography';
import { AgentVerticle } from '../../users/api/types';

const contactDetails = [
    {
        name: 'ukGlobalOperations',
        phone: UK_GLOBAL_OPERATIONS,
    },
    {
        name: 'nlOperations',
        phone: NL_OPERATIONS,
    },
];

const priorityContactDetails = [
    {
        name: 'europePriority',
        phone: EUROPE_PRIORITY,
    },
    {
        name: 'globalPriority',
        phone: GLOBAL_PRIORITY,
    },
];

const ContactUsModal: FunctionComponent<{
    onClose: () => void;
}> = ({ onClose }) => {
    const { t } = useTranslation();
    const classes = typographyStyles();
    const buttonClasses = useButtonStyles();
    const [showCopyIcon, setShowCopyIcon] = useState(false);
    const [copied, setCopied] = useState(false);
    const { currentProfile } = useCurrentProfileContext();

    const contacts = currentProfile.agentVerticle === AgentVerticle.AIRLINE ? priorityContactDetails : contactDetails;

    const handleClick = (): void => {
        onClose();
        openZendeskLiveChat();
    };

    const handleCopyClick = (text: string): void => {
        setCopied(true);
        copy(text);
    };

    const handleMouseLeave = (): void => {
        setShowCopyIcon(false);
        setCopied(false);
    };

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onClose}
            title={t('modals.contact.title')}
            cancelButtonLabel={t('buttonName.close')}
        >
            <Grid container>
                <Grid item>
                    <Typography variant="body1" component="p">
                        {t('modals.contact.description')}
                    </Typography>
                    <section style={{ marginTop: '2em' }}>
                        <article>
                            <Typography
                                variant="h6"
                                component="h3"
                                className={clsx(classes.subtitle1Override, classes.capitalize)}
                            >
                                {t('modals.contact.liveChat')}
                            </Typography>
                            <Button
                                onClick={handleClick}
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                className={buttonClasses.defaultTextButton}
                            >
                                {t('modals.contact.startAConversation')}
                                <ArrowForwardIcon color="secondary" />
                            </Button>
                        </article>
                    </section>
                    <section style={{ marginTop: '2em' }}>
                        <article>
                            <Typography
                                variant="h6"
                                component="h3"
                                className={clsx(classes.subtitle1Override, classes.capitalize)}
                            >
                                {t('email')}
                            </Typography>
                            <div
                                style={{
                                    height: '19px',
                                    paddingBottom: '0.75em',
                                }}
                                onMouseOver={() => setShowCopyIcon(true)}
                                onMouseLeave={() => handleMouseLeave()}
                            >
                                <Link href={`mailto:${t('contact.emailAddress')}`}>{t('contact.emailAddress')}</Link>
                                {showCopyIcon ? (
                                    <Typography
                                        component="span"
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'block',
                                            },
                                        }}
                                    >
                                        <Button
                                            className={`${buttonClasses.iconButton} 
                                        ${copied ? classes.successTheme : ''}`}
                                            data-testid="email-button"
                                            disableRipple
                                            onClick={() => handleCopyClick(t('contact.emailAddress'))}
                                        >
                                            {copied ? <DoneIcon /> : <FileCopyIcon />}
                                        </Button>
                                    </Typography>
                                ) : null}
                            </div>
                        </article>
                    </section>
                    <section style={{ marginTop: '2em' }}>
                        <article>
                            <Typography
                                variant="h6"
                                component="h3"
                                className={clsx(classes.subtitle1Override, classes.capitalize)}
                            >
                                {t('modals.contact.phoneAndWhatsapp')}
                            </Typography>
                            <Grid container flexDirection="column">
                                {contacts.map(details => (
                                    <Box
                                        key={details.name}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '.25rem',
                                        }}
                                        onClick={() =>
                                            window.open(
                                                `${WEB_WHATSAPP_REDIRECTING}${details.phone.replace(/\s/g, '')}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        <IconedData
                                            icon={PhoneOutlinedIcon}
                                            dataLabel={`${t(`contact.${details.name}`)}: `}
                                            data={details.phone}
                                            dataLabelColor={COLORS.SLATE_GREY}
                                            isLinked
                                            bottomMargin="0"
                                        />
                                        <IconButton sx={{ color: COLORS.BLUE_DARK }}>
                                            <WhatsAppIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Grid>
                        </article>
                    </section>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ContactUsModal;
