import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import ErrorPage from '../../components/errorPage/ErrorPage';
import config from '../../config';
import { INVALID_INVITE_TOKEN_PAGE } from '../../constants/amplitude/commonKeys';
import TokenError from '../../constants/TokenError';
import { emailIcon } from '../../public/assets/icons';

const InvalidInviteToken = ({ errorType }: { errorType: TokenError }) => {
    const { t } = useTranslation();
    const isPageOpened = useRef(false);

    const localisedKey = (): string => {
        switch (errorType) {
            case TokenError.NotFound:
                return 'invalidInviteToken';
            case TokenError.Expired:
                return 'expiredInviteToken';
            default:
                throw new Error(`${errorType as string} is not a valid TokenError.`);
        }
    };

    const handleClick = (): void => {
        window.location.href = config.geteUrl;
    };

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(INVALID_INVITE_TOKEN_PAGE);
    }

    return (
        <ErrorPage
            icon={emailIcon}
            header={t(`errors.${localisedKey()}.description`)}
            description={t(`errors.${localisedKey()}.nextSteps`)}
            buttonTitle={t('buttonName.visitGetE')}
            onButtonClick={handleClick}
        />
    );
};

export default InvalidInviteToken;
