/* eslint-disable import/no-anonymous-default-export */
export default {
    appUrl: 'APP_URL',
    logOutUrl: 'LOG_OUT_URL',
    iframeUrl: 'IFRAME_URL',
    signInUrl: 'SIGN_IN_URL',
    bookingToolUrl: 'https://{subDomain}.book-test.get-e.com',
    forgottenPasswordUrl: 'FORGOTTEN_PASSWORD',
    setCookieUrl: 'COOKIE_URL',
    refreshCookieUrl: 'REFRESH_COOKIE_URL',
    deleteCookieUrl: 'DELETE_COOKIE_URL',
    googleAddressKey: 'GOOGLE_ADDRESS_KEY',
    apiUrl: 'API_URL',
    useNewAuthentication: false,
    amplitudeApi: 'AMPLITUDE_API',
};
