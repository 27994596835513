import { AuthenticatedUser } from '../context/AuthenticatedUserContext';
import apiClient from './api';
import { CSRF_COOKIE, LOGIN, LOGOUT } from './routes';

export const login = async (email: string, password: string): Promise<AuthenticatedUser> => {
    await apiClient.get(CSRF_COOKIE);

    const { data } = await apiClient.post<AuthenticatedUser>(LOGIN, {
        email,
        password,
    });

    return data;
};

export const logout = async (): Promise<void> => {
    await apiClient.post(LOGOUT);
};
