import { useQuery } from 'react-query';

import { USE_DOCUMENTS } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import { DOCUMENTS } from '../../../services/routes';
import { DocumentItem } from './types';

export function useDocuments() {
    const { data, isLoading } = useQuery([USE_DOCUMENTS], () => getDocuments(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
    });

    return {
        data: data ?? [],
        isLoading,
    };
}

export const getDocuments = async (): Promise<DocumentItem[]> => {
    const { data } = await apiClient.get<DocumentItem[]>(DOCUMENTS);

    return data;
};
