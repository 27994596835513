import { useQuery } from 'react-query';

import { USE_BOOKING_TOOL_URL } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import { BOOKING_TOOL_URL } from '../../../services/routes';
import { BookingToolUrlResponse } from './types';

export const useBookingTool = () => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([USE_BOOKING_TOOL_URL], () => getBookingToolUrl(), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: false,
    });

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getBookingToolUrl = async (): Promise<BookingToolUrlResponse> => {
    const { data } = await apiClient.get<BookingToolUrlResponse>(BOOKING_TOOL_URL);

    return data;
};
