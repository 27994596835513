import { SecondaryButton } from '@get-e/react-components';
import { Download, StarOutline, Mail, EditOutlined, DeleteOutlineOutlined, ReceiptLongOutlined } from '@mui/icons-material';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useHistory } from 'react-router-dom';
import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { CONFIRM_CANCELLATION_RIDE, DONWLOAD_DOCUMENTATION, EDIT_RIDE } from '../../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { getUpdateRideRoute } from '../../../../constants/urlPaths';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import { getRideStatusKey, RideStatus } from '../../../../helpers/rideStatusUtil';
import theme from '../../../../styles/theme';
import StatusBadge from '../../../rides/components/StatusBadge';
import downloadBookingConfirmation from '../../../rides/customer/api/downloadBookingConfirmation';
import downloadReceipt from '../../../rides/customer/api/downloadReceipt';
import { RideResponse, RideAmplitudeEventProperties, PAYMENT_METHOD } from '../../api/types';
import CancelRideConfirmModal from '../../components/CancelRideConfirmModal';
import SendBookingConfirmationModal, { BookingConfirmationEmail } from '../../components/SendBookingConfirmationModal';
import { cancelRide } from '../api';
import useCarInformation from '../hooks/useCarInformation';
import RideReviewModal from './RideReviewModal';

const useStyles = makeStyles(styleTheme => ({
    mainWrapper: {
        alignItems: 'center',
        paddingLeft: '2rem',
        [styleTheme.breakpoints.down('lg')]: { padding: '0' },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        color: COLORS.BLUE,
    },
    headerTitleWrapper: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('lg')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '2rem',
            minHeight: '2.75rem',
        },
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 'auto !important',
        [styleTheme.breakpoints.up('lg')]: { marginBottom: 0 },
    },
    headerText: {
        fontSize: '1.5rem',
        fontWeight: 700,
        paddingRight: '1rem',
        [styleTheme.breakpoints.down('md')]: { fontSize: '1.2rem' },
    },
    subheaderText: {
        fontWeight: 400,
        fontSize: '0.75rem',
        marginBottom: '2rem',
        marginTop: '-0.5rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginRight: '0.5rem',
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem',
            marginRight: '0.3rem',
        },
        [styleTheme.breakpoints.down('lg')]: { marginBottom: '1rem' },
    },
    editButton: {
        marginRight: '1rem',
        border: `1px solid ${COLORS.BLUE}`,
        color: COLORS.BLUE,
    },
    reviewButton: {
        marginRight: '1rem',
        border: `1px solid ${COLORS.BLUE}`,
        color: COLORS.BLUE,
        width: '163px !important',
    },
    buttons: {
        width: '174px',
        [styleTheme.breakpoints.down('md')]: {
            width: '146px',
            fontSize: '13px',
            height: '40px',
        },
    },
    editButtonIcon: { color: COLORS.BLUE },
    deleteButtonIcon: { color: COLORS.RED },
}));

interface CustomerRideHeaderProps {
    data?: RideResponse;
    refetch: () => void;
    rideId: string;
}

const RideHeader = ({ data, refetch, rideId }: CustomerRideHeaderProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { selectedVehicle } = useCarInformation();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const [isCancelRideModalOpen, setIsCancelRideModalOpen] = useState(false);
    const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const history = useHistory();

    let initialBookingConfirmationEmails: BookingConfirmationEmail[] = [];

    if (data) {
        initialBookingConfirmationEmails = data?.passengers.map(passenger => ({
            id: passenger.email,
            email: passenger.email,
            isIncluded: passenger.isLead,
        }));
    }

    const { mutate: downloadBookingConfirmationMutation } = useMutation(downloadBookingConfirmation);
    const { mutate: downloadReceiptMutation } = useMutation(downloadReceipt);

    const onDownloadBookingConfirmationClick = () => {
        if (data) {
            downloadBookingConfirmationMutation({
                unid: data.unid,
                prettifiedUnid: data.prettifiedUnid,
            });

            logAmplitudeEvent(DONWLOAD_DOCUMENTATION, rideAmplitudeEventProperties);
        }
    };

    const onDownloadReceiptClick = () => {
        if (data) {
            downloadReceiptMutation({
                unid: data.unid,
                prettifiedUnid: data.prettifiedUnid,
            });

            logAmplitudeEvent(DONWLOAD_DOCUMENTATION, rideAmplitudeEventProperties);
        }
    };

    const rideAmplitudeEventProperties = useMemo(() => {
        return {
            'Ride number': data?.prettifiedUnid,
            'Ride created time': dayjs.utc(data?.createdAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride pickup time': dayjs.utc(data?.pickUp.departAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride customer': data?.customer,
            'Ride supplier': data?.supplier,
            'Ride status': data?.status,
            'Ride situation tags': data?.situationTag,
            'Ride qc status': data?.qcStatus,
            'Ride has driver': Boolean(data?.driver),
        } as RideAmplitudeEventProperties;
    }, [
        data?.createdAt,
        data?.customer,
        data?.driver,
        data?.pickUp.departAt,
        data?.prettifiedUnid,
        data?.qcStatus,
        data?.situationTag,
        data?.status,
        data?.supplier,
    ]);

    const { mutate: cancelRideMutation, isLoading: isLoadingCancelRide } = useMutation(cancelRide, {
        onSuccess: () => {
            showNotification(t('pages.rides.successCancelRide'), Severity.Info);
            setIsCancelRideModalOpen(false);
            refetch();
            logAmplitudeEvent(CONFIRM_CANCELLATION_RIDE, rideAmplitudeEventProperties);
        },
        onError: (error: Error) => {
            showNotification(t('errors.cancelRide'), Severity.Error);
            setIsCancelRideModalOpen(false);
        },
    });

    return (
        <Grid container className={classes.mainWrapper}>
            <Grid item xs className={classes.header}>
                <Grid item xs className={classes.headerTitleWrapper}>
                    <Grid item className={classes.headerTitle}>
                        <Grid display="flex" alignItems="center">
                            <Typography className={classes.headerText}>
                                {t('ride')} {data?.prettifiedUnid}
                            </Typography>
                            {data && (
                                <StatusBadge
                                    status={data?.status}
                                    trackingStatus={data?.trackingStatus}
                                    pickupDateTimeUtc={data?.pickUp.departAt}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs
                            display="flex"
                            alignItems={isMobile ? 'flex-start' : 'center'}
                            flexDirection={isMobile ? 'column' : 'row'}
                            marginTop="1rem"
                        >
                            <Typography
                                className={classes.subheaderText}
                                onClick={() => setIsSendBookingConfirmationModalOpen(true)}
                            >
                                <Mail />
                                {t('pages.singleRide.sendBookingInfo')}
                            </Typography>
                            <Typography className={classes.subheaderText} onClick={() => onDownloadBookingConfirmationClick()}>
                                <Download />
                                {t('pages.singleRide.downloadBookingConfirmation')}
                            </Typography>
                            {data?.paymentMethod === PAYMENT_METHOD.CREDIT_CARD &&
                                (data?.status === getRideStatusKey(RideStatus.CONFIRMED) ||
                                    data?.status === getRideStatusKey(RideStatus.COMPLETED)) && (
                                    <Typography className={classes.subheaderText} onClick={() => onDownloadReceiptClick()}>
                                        <ReceiptLongOutlined />
                                        {t('pages.rides.downloadReceipt')}
                                    </Typography>
                                )}
                        </Grid>
                    </Grid>

                    <Grid item>
                        {data?.status === getRideStatusKey(RideStatus.COMPLETED) && (
                            <SecondaryButton
                                onClick={() => setIsReviewModalOpen(true)}
                                className={clsx(classes.reviewButton, classes.buttons)}
                                loading={false}
                            >
                                <StarOutline className={classes.editButtonIcon} />
                                {t('pages.singleRide.reviewRide')}
                            </SecondaryButton>
                        )}
                        {data?.status === getRideStatusKey(RideStatus.BOOKED) &&
                            data?.status !== getRideStatusKey(RideStatus.COMPLETED) && (
                                <SecondaryButton
                                    onClick={() => {
                                        history.push(getUpdateRideRoute(rideId));
                                        logAmplitudeEvent(EDIT_RIDE, rideAmplitudeEventProperties);
                                    }}
                                    className={clsx(classes.editButton, classes.buttons)}
                                    loading={false}
                                >
                                    <EditOutlined className={classes.editButtonIcon} />
                                    {t('pages.singleRide.editRide')}
                                </SecondaryButton>
                            )}
                        {data?.status === getRideStatusKey(RideStatus.BOOKED) &&
                            data?.status !== getRideStatusKey(RideStatus.COMPLETED) && (
                                <SecondaryButton
                                    onClick={() => setIsCancelRideModalOpen(true)}
                                    className={classes.buttons}
                                    variation="danger"
                                >
                                    <DeleteOutlineOutlined className={classes.deleteButtonIcon} />
                                    {t('pages.singleRide.cancelRide')}
                                </SecondaryButton>
                            )}
                    </Grid>
                </Grid>
            </Grid>
            {isCancelRideModalOpen && (
                <CancelRideConfirmModal
                    onClose={() => setIsCancelRideModalOpen(false)}
                    onConfirm={() => cancelRideMutation(rideId)}
                    cancellationPolicies={data?.cancellationPolicies || []}
                    selectedVehicle={selectedVehicle}
                    isLoading={isLoadingCancelRide}
                />
            )}
            {isSendBookingConfirmationModalOpen && (
                <SendBookingConfirmationModal
                    initialBookingConfirmationEmails={initialBookingConfirmationEmails}
                    isOpen={isSendBookingConfirmationModalOpen}
                    onClose={() => setIsSendBookingConfirmationModalOpen(false)}
                    rideId={rideId}
                />
            )}
            {isReviewModalOpen && (
                <RideReviewModal isOpen={isReviewModalOpen} onClose={() => setIsReviewModalOpen(false)} feedbacks={[]} />
            )}
        </Grid>
    );
};

export default RideHeader;
