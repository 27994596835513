import { Rating, Typography, useMediaQuery, Link } from '@mui/material';
import { Container } from '@mui/system';
import { GridRowParams, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridRowId } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DetailPanelToggle } from '../../../../components/detailPanelToggle/DetailPanelToggle';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { getSingleRideRoute } from '../../../../constants/urlPaths';
import useDataGridStyles from '../../../../styles/DataGrid';
import theme from '../../../../styles/theme';

const COLUMN_WIDTH = 230;

export const useReviewsColumns = () => {
    const classes = useDataGridStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();

    return useMemo(() => {
        const columns = [
            {
                field: 'pickUpTime',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container className={clsx(classes.tableHeader, { [classes.noRightBorder]: isMobile })}>
                        <Typography>{isMobile ? t('ride') : t('pages.reports.pickUpTime')}</Typography>
                    </Container>
                ),
                renderCell: (params: GridRowParams) => (
                    <>{formatInTimeZone(params.row.ride.pickUpTime, 'UTC', DATE_FORMATS['d MMM yyyy at p'])}</>
                ),
            },
            {
                field: 'rideNumber',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container className={classes.tableHeader}>
                        <Typography>{t('rideNumber')}</Typography>
                    </Container>
                ),
                renderCell: (params: GridRowParams) => (
                    <Link
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();

                            const rideRoute = getSingleRideRoute(params?.row?.ride?.rideNumber?.toString()?.replace(/-/g, ''));

                            if (!rideRoute) {
                                return;
                            }

                            if (event && event.button === 0 && event.metaKey) {
                                event.preventDefault();
                                window.open(rideRoute, '_blank');
                                return;
                            }

                            history.push(rideRoute);
                        }}
                    >
                        <Typography color={COLORS.BLUE}>{params.row.ride.rideNumber}</Typography>
                    </Link>
                ),
            },
            {
                field: 'driver',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container className={classes.tableHeader}>
                        <Typography>{t('driver')}</Typography>
                    </Container>
                ),
                renderCell: (params: GridRowParams) => <>{params.row.ride.driver}</>,
            },
            {
                field: 'averageRating',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                headerClassName: classes.hideSeparator,
                sortable: false,
                disableColumnMenu: true,
                renderHeader: () => (
                    <Container className={clsx(classes.tableHeader, classes.noRightBorder)}>
                        <Typography>{t('averageRating')}</Typography>
                    </Container>
                ),
                renderCell: (params: GridRowParams) => (
                    <>
                        {params.row.averageRate}
                        <Rating name="averageRate" value={params.row.averageRate} readOnly sx={{ paddingLeft: '1rem' }} />
                    </>
                ),
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerClassName: classes.hideSeparator,
                renderCell: (params: { id: GridRowId; value: boolean }) => (
                    <DetailPanelToggle id={params.id} value={params.value} />
                ),
            },
        ];

        return columns;
    }, [t, classes.hideSeparator, classes.tableHeader, classes.noRightBorder, isMobile, history]);
};
