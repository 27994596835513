export const DUMMY_DATE = '1970-01-01';

export enum REPEATS {
    DO_NOT_REPEAT = 'Do not repeat',
    EVERY_MONDAY = 'Every Monday',
    EVERY_TUESDAY = 'Every Tuesday',
    EVERY_WEDNESDAY = 'Every Wednesday',
    EVERY_THURSDAY = 'Every Thursday',
    EVERY_FRIDAY = 'Every Friday',
    EVERY_SATURDAY = 'Every Saturday',
    EVERY_SUNDAY = 'Every Sunday',
    CUSTOM = 'Custom',
}

export enum ADJUSTMENT_REPEATS {
    DOES_NOT_REPEAT = 'Does not repeat',
    REPEATS_DAILY = 'Repeats daily',
    REPEATS_WEEKLY = 'Repeats weekly',
    REPEATS_YEARLY = 'Repeats yearly',
}

export enum ADJUSTMENT_REPEATS_VALUES {
    DOES_NOT_REPEAT = 'NONE',
    REPEATS_DAILY = 'DAILY',
    REPEATS_WEEKLY = 'WEEKLY',
    REPEATS_YEARLY = 'YEARLY',
}

export enum ADJUSTMENT_REPEATS_SUFFIX {
    DOES_NOT_REPEAT = 'One time occasion',
    REPEATS_DAILY = 'Every day from and to a specific time',
    REPEATS_WEEKLY = 'Every selected day of the week',
    REPEATS_YEARLY = 'Every selected day of the year',
}

export enum WEEKDAYS {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}

export enum WEEKDAY_SHORT_NAMES {
    MONDAY = 'Mon',
    TUESDAY = 'Tue',
    WEDNESDAY = 'Wed',
    THURSDAY = 'Thu',
    FRIDAY = 'Fri',
    SATURDAY = 'Sat',
    SUNDAY = 'Sun',
}

export const PERIODS = {
    LAST_30_DAYS: {
        label: 'Last 30 days',
        value: 29,
        translationKey: 'periodPicker.last30Days',
    },
    LAST_7_DAYS: {
        label: 'Last 7 days',
        value: 6,
        translationKey: 'periodPicker.last7Days',
    },
    YESTERDAY: {
        label: 'Yesterday',
        value: 0,
        translationKey: 'periodPicker.yesterday',
    },
};

export enum USER_ROLES {
    MANAGE_RIDES_BOOKED_BY_OTHERS = 'MANAGE_RIDES_BOOKED_BY_OTHERS',
    MANAGE_USERS = 'MANAGE_USERS',
}
