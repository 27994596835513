import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { USE_RIDES } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import { RIDES } from '../../../services/routes';
import { RideResponse } from '../../ride/api/types';
import { RidesVariables } from './types';

export const useRides = (variables: Partial<RidesVariables>) => {
    const { query = '', startDate, endDate, statusFilters } = variables;

    const { data, refetch, isLoading, isRefetching } = useQuery(
        [USE_RIDES + query + statusFilters + startDate + endDate],
        () => listRides(variables),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
            cacheTime: 500,
        }
    );

    return useMemo(
        () => ({
            data: data ?? [],
            refetch,
            isLoading,
            isRefetching,
        }),
        [data, isLoading, isRefetching, refetch]
    );
};

export const listRides = async (variables: Partial<RidesVariables>): Promise<RideResponse[]> => {
    const { query = '', startDate, endDate, statusFilters } = variables;
    const filterEmptyStrings = arr => Array.isArray(arr) && arr.filter(item => item !== '');

    const filteredParams = {
        query,
        startDate,
        endDate,
        ...(filterEmptyStrings(statusFilters) && { statusFilters }),
    };

    const { data } = await apiClient.get<RideResponse[]>(RIDES, { params: filteredParams });

    return data;
};
