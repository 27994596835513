import { createContext, useContext } from 'react';

import { UseQueryResult } from 'react-query';
import { User } from '../pages/users/api/types';

export interface CurrentProfileContextProps {
    currentProfile: User;
    refetchUserProfile: () => Promise<UseQueryResult>;
    isMenuClosed: boolean;
}

export const CurrentProfileContext = createContext<CurrentProfileContextProps | null>(null);

export const useCurrentProfileContext = (): CurrentProfileContextProps => {
    const currentProfileContext = useContext(CurrentProfileContext);

    if (currentProfileContext === null) {
        throw new Error('CurrentProfileContext should not be null');
    }

    return currentProfileContext;
};
