import { TextField } from '@get-e/react-components';
import { Box, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { SINGLE_RIDE_ADD_REMARKS_BOOKING_REFERENCE } from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { RideContext } from '../context/RideContext';

interface ReferencesCardProps {
    reference?: string | null;
    setReference: Dispatch<SetStateAction<string | null>>;
    isVisible?: boolean;
    isDisabled?: boolean;
    isTitleDisabled?: boolean;
    order?: number;
}

const ReferenceCard = ({ reference, setReference, isVisible, isDisabled, isTitleDisabled, order }: ReferencesCardProps) => {
    const { t } = useTranslation();
    const { amplitudeEventProperties } = useContext(RideContext);

    if (!isVisible) {
        return null;
    }

    return (
        <Box paddingBottom="1rem" order={order ?? 0}>
            <Typography
                style={{
                    fontWeight: 700,
                    color: isTitleDisabled ? COLORS.SLATE_GREY : COLORS.BLUE_DARK,
                    marginBottom: '0.5rem',
                }}
            >
                {t('pages.rides.referenceNumber')}
            </Typography>
            {isDisabled && <Typography>{reference ?? t('notAvailable')}</Typography>}
            {!isDisabled && (
                <TextField
                    label={t('reference')}
                    value={reference}
                    onChange={event => setReference(event.target.value)}
                    onBlur={() => logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS_BOOKING_REFERENCE, amplitudeEventProperties)}
                    disabled={isDisabled}
                    noHelperTextSpace
                />
            )}
        </Box>
    );
};

export default ReferenceCard;
