import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { USE_INVITED_USERS, USE_USER_PROFILE } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import {
    INVITED_USERS,
    USER_INVITE,
    USER_PROFILE,
    getRevokeUserRoute,
    getUpdateUserStatusRoute,
    getUserRoute,
    getUsersRoute,
} from '../../../services/routes';
import { EditUserRequest, InviteUserRequest, User, UserProfileRequest } from './types';

export const useUsers = (key: string, isDeactivated: boolean) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([key], () => getUsers(isDeactivated), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

export const useInvitedUsers = () => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([USE_INVITED_USERS], () => getInvitedUsers(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getUsers = async (isDeactivated: boolean): Promise<User[]> => {
    const { data } = await apiClient.get<User[]>(getUsersRoute(isDeactivated));

    return data;
};

const getInvitedUsers = async (): Promise<User[]> => {
    const { data } = await apiClient.get<User[]>(INVITED_USERS);

    return data;
};

export const inviteUser = async (payload: InviteUserRequest): Promise<AxiosResponse> => {
    const response = await apiClient.post(USER_INVITE, payload);

    return response;
};

export const editUser = async (id: string, payload: EditUserRequest): Promise<AxiosResponse> => {
    const response = await apiClient.put(getUserRoute(id), payload);

    return response;
};

export const revokeUser = async (id: string): Promise<AxiosResponse> => {
    const response = await apiClient.put(getRevokeUserRoute(id));

    return response;
};

export const updateUserStatus = async (id: string, isDeactivated: boolean): Promise<AxiosResponse> => {
    const response = await apiClient.put(getUpdateUserStatusRoute(id), { isDeactivated });

    return response;
};

export const updateUserProfile = async (variables: UserProfileRequest): Promise<AxiosResponse> => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { email, firstName, lastName, password, password_confirmation } = variables;

    const payload = {
        email,
        firstName,
        lastName,
    } as UserProfileRequest;

    if (password && password_confirmation) {
        payload.password = password;
        payload.password_confirmation = password_confirmation;
    }

    const response = await apiClient.put(USER_PROFILE, payload);

    return response;
};

export const useUserProfile = () =>
    useQuery([USE_USER_PROFILE], () => getUserProfile(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 600000,
        staleTime: 600000,
    });

export const getUserProfile = async (): Promise<User> => {
    const { data } = await apiClient.get<User>(USER_PROFILE);

    return data;
};
