import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import { Severity, useNotificationContext } from '../../context/NotificationContext';

interface CopyToClipboardButtonProps {
    text: string;
    size?: string;
    padding?: string;
    iconMarginRight?: string;
}

const CopyToClipboardButton = ({ text, size, padding, iconMarginRight }: CopyToClipboardButtonProps) => {
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        showNotification(t('copiedToClipboard'), Severity.Info);
        return navigator.clipboard.writeText(text);
    };

    return (
        <IconButton onClick={handleClick} style={{ padding: padding ?? '0.5rem' }}>
            <ContentCopyOutlinedIcon
                style={{
                    fontSize: size ?? '1.25rem',
                    color: COLORS.SLATE_GREY,
                    marginRight: iconMarginRight ?? '0',
                }}
            />
        </IconButton>
    );
};

export default CopyToClipboardButton;
