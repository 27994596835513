import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { getFileRoute } from '../../../services/routes';
import { DownloadDocumentParams } from './types';

const downloadDocument = async (variables: DownloadDocumentParams): Promise<void> => {
    const { id, fileName, onSuccess, onError } = variables;

    return apiClient.get(getFileRoute(id), { responseType: 'blob' }).then(
        response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, fileName);
            onSuccess();
        },
        () => {
            onError();
        }
    );
};

export default downloadDocument;
