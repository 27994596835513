import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { getDownloadRideOrderRoute } from '../../../services/routes';

const downloadRideOrder = async (id: string): Promise<unknown> => {
    const result = await apiClient
        .get(getDownloadRideOrderRoute(id), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, `GET-E Ride order ${id}.pdf`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadRideOrder;
