import { PortalLocale } from '../context/LocaleContext';
import apiClient from '../services/api';
import findZendeskLocale from './findZendeskLocale';

const setZendeskLocale = (locale: PortalLocale): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('messenger:set', 'locale', findZendeskLocale(locale));
};

const openZendeskLiveChat = (): void => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('messenger', 'show');
};

const authenticateZendeskUser = () => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('messenger', 'loginUser', async done => {
        try {
            const { token } = await getZendeskToken();

            if (token) {
                done(token);
            }
        } catch (error) {
            console.error('Error fetching token:', error);
        }
    });
};

const logoutZendeskUser = () => {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('messenger', 'logoutUser');
};

const getZendeskToken = async (): Promise<{ token: string }> => {
    const response = await apiClient.get<{ token: string }>('/zendesk/get-contact-token');

    return response.data;
};

export { setZendeskLocale, openZendeskLiveChat, logoutZendeskUser, authenticateZendeskUser };
