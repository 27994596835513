import { useQuery } from 'react-query';

import { USE_RIDE } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import { getRideRoute } from '../../../services/routes';
import { RideResponse } from './types';

export function useRide(id: string) {
    return useQuery([USE_RIDE], () => viewRide(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
    });
}

export const viewRide = async (id: string): Promise<RideResponse> => {
    const { data } = await apiClient.get<RideResponse>(getRideRoute(id));

    return data;
};
