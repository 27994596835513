import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';

const useRidesStyles = makeStyles(styleTheme => ({
    addRideButton: { width: BUTTON_WIDTH },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 2rem',
    },
    filterButtonsWrapper: {
        paddingTop: '2rem',
        display: 'flex',
        flexDirection: 'row',
        [styleTheme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingTop: '1rem',
            overflow: 'auto',
        },
        justifyContent: 'space-between',
    },
    multipleSelectButtonsWrapper: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        '&::-webkit-scrollbar': {
            height: 0,
            width: 0,
            display: 'none',
        },
        '& [class*="MuiButtonBase-root"]': {
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            '&:nth-of-type(1)': {
                marginLeft: 0,
            },
            marginLeft: '16px',
            borderRadius: '8px',
            cursor: 'pointer !important',
        },
    },
    mulipleSelectButtonsOld: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        '&::-webkit-scrollbar': {
            height: 0,
            width: 0,
            display: 'none',
        },
    },
    resetFilters: {
        fontFamily: 'inherit',
        color: COLORS.BLUE,
        '&:hover': { color: COLORS.BLUE_DARK },
    },
    resultsFilterButtonWrapper: {
        display: 'flex',
        [styleTheme.breakpoints.down('md')]: { marginTop: '2rem' },
    },
    noResultsContentWrapper: {
        border: `1px solid ${COLORS.BLUE}`,
        borderRadius: '4px',
        padding: '0.625rem 1.25rem !important',
        marginTop: '2rem',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            borderColor: COLORS.BLUE_DARK,
            cursor: 'pointer',
        },
    },
    totalCount: {
        padding: 0,
        width: 'auto',
    },
    totalCountLabel: {
        marginRight: 0,
        color: `${COLORS.SLATE_GREY} !important`,
    },
    numberedIcon: {
        color: COLORS.WHITE,
        backgroundColor: COLORS.ORANGE,
        lineHeight: '1rem',
    },
    numberedIconZero: {
        color: COLORS.BLUE,
        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
        lineHeight: '1rem',
    },
}));

export default useRidesStyles;
