/* eslint-disable no-warning-comments */
import envConfig from './environment/config';

const config = {
    appUrl: envConfig.appUrl,
    logOutUrl: envConfig.logOutUrl,
    iframeUrl: envConfig.iframeUrl,
    signInUrl: envConfig.signInUrl,
    bookingToolUrl: envConfig.bookingToolUrl,
    forgottenPasswordUrl: envConfig.forgottenPasswordUrl,
    geteUrl: 'https://get-e.com',
    setCookieUrl: envConfig.setCookieUrl,
    refreshCookieUrl: envConfig.refreshCookieUrl,
    deleteCookieUrl: envConfig.deleteCookieUrl,
    minimumPasswordLength: 8,
    legacyIframeId: 'legacy-iframe',
    paginationLimit: 10,
    googleAddressKey: envConfig.googleAddressKey,
    apiUrl: envConfig.apiUrl,
    useNewAuthentication: envConfig.useNewAuthentication,
    amplitudeApi: envConfig.amplitudeApi,

    // TODO: Set during build
    appVersion: '0.0.1',
    sentryDsn: 'https://14abdcf586784e6ba2cabaadb7e089d2@sentry.io/259970',
};

export default config;
