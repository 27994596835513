import { AxiosResponse } from 'axios';

import apiClient from '../../../services/api';
import { RESET_PASSWORD } from '../../../services/routes';
import { ResetPasswordRequest } from './types';

export const resetPassword = async (request: ResetPasswordRequest): Promise<AxiosResponse> => {
    const response = await apiClient.post<ResetPasswordRequest>(RESET_PASSWORD, request);

    return response;
};
