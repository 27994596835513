import { FullScreenLoader } from '@get-e/react-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';

import { useCurrentProfileContext } from '../../../../context/CurrentProfileContext';
import { AccountType } from '../../../../services/types';
import { default as CustomerRides } from '../../customer/Rides';
import { default as CustomerRidesNew } from '../../customer/RidesNew';
import { default as SupplierRides } from '../../supplier/Rides';
import { default as SupplierRidesNew } from '../../supplier/RidesNew';

const RidesPageSwitch: FunctionComponent = () => {
    const {
        currentProfile: { accountType },
    } = useCurrentProfileContext();

    const { useNewSupplierOverview, useNewCustomerOverview } = useFlags();

    switch (accountType) {
        case AccountType.CUSTOMER:
            return useNewCustomerOverview ? <CustomerRidesNew /> : <CustomerRides />;
        case AccountType.SUPPLIER:
            return useNewSupplierOverview ? <SupplierRidesNew /> : <SupplierRides />;
        default:
            return <FullScreenLoader />;
    }
};

export default RidesPageSwitch;
