import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, IconButton, Snackbar, useMediaQuery } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';

import { COLORS } from '../../constants/colors';
import { NOTIFICATION_HEIGHT } from '../../constants/content';
import { MENU_STATE } from '../../constants/windowStorageKeys';
import { MenuState } from '../../pages/pageContainer/PageContainer';
import theme from '../../styles/theme';
import { CLOSED_DRAWER_WITH, DRAWER_WIDTH } from '../navigation/NavigationMenu.styles';

export interface NotificationProps {
    isOpen: boolean;
    onClose: () => void;
    content: ReactElement;
    backgroundColor?: string;
    textColor?: string;
    verticalPosition?: 'top' | 'bottom';
    className?: string;
}

const Notification = ({
    content,
    isOpen,
    backgroundColor,
    textColor,
    onClose,
    verticalPosition,
    className,
}: NotificationProps) => {
    const isMenuClosed = localStorage.getItem(MENU_STATE) === MenuState.Closed;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const left = useMemo(() => {
        if (isMobile) {
            return 0;
        }

        if (isMenuClosed) {
            return `${CLOSED_DRAWER_WITH}px !important`;
        }

        return `${DRAWER_WIDTH}px !important`;
    }, [isMenuClosed, isMobile]);

    const width = useMemo(() => {
        if (isMobile) {
            return '100%';
        }

        if (isMenuClosed) {
            return `calc(100% - ${`${CLOSED_DRAWER_WITH}px`})`;
        }

        return `calc(100% - ${`${DRAWER_WIDTH}px`})`;
    }, [isMenuClosed, isMobile]);

    const visibility = useMemo(() => {
        return isMobile && !isMenuClosed ? 'hidden' : 'visible';
    }, [isMenuClosed, isMobile]);

    return (
        <Snackbar
            sx={{
                height: NOTIFICATION_HEIGHT,
                width,
                position: 'fixed !important',
                top: '0 !important',
                left,
                transition: theme.transitions.create(['left', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                transform: 'none !important',
                visibility,
            }}
            className={className}
            open={isOpen}
            anchorOrigin={{
                vertical: verticalPosition ?? 'top',
                horizontal: 'center',
            }}
            disableWindowBlurListener={true}
            onClose={(_event, reason) => {
                if (reason !== 'clickaway') {
                    onClose();
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: isMobile ? '0.5rem 1.5rem' : '0 1.5rem',
                    height: '100%',
                    width: '100%',
                    color: COLORS.WHITE ?? textColor,
                    background: COLORS.ORANGE_WARNING ?? backgroundColor,
                }}
            >
                <WarningAmberIcon sx={{ marginRight: '0.75rem' }} />
                {content}
                <IconButton
                    sx={{
                        color: COLORS.WHITE ?? textColor,
                        marginLeft: 'auto',
                    }}
                    name="details"
                    onClick={onClose}
                >
                    <CloseOutlinedIcon />
                </IconButton>
            </Box>
        </Snackbar>
    );
};

export default Notification;
