import { makeStyles } from '@mui/styles';

import { COLORS } from '../../../constants/colors';

const rideStyles = makeStyles(styleTheme => ({
    headerTitleWrapper: {
        marginBottom: '2rem',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        [styleTheme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
        },
    },
    headerSecondaryTitle: {
        color: COLORS.BLUE,
        fontSize: '1.5rem',
        fontWeight: 700,
    },
    mainWrapper: {
        alignItems: 'center',
        padding: '0 2rem',
    },
    mainWrapperTopPadding: { paddingTop: '3.5rem' },
    referenceAndRemarksButton: { height: '44px' },
}));

export default rideStyles;
