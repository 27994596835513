/* eslint-disable max-statements */
import chameleon from '@chamaeleonidae/chmln';
import { FullScreenLoader } from '@get-e/react-components';
import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import clsx from 'clsx';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { setAmplitudeUserId, setAmplitudeUserProperties } from '../../amplitude/amplitude';
import LogInMessage from '../../components/loginMessage/LogInMessage';
import NavigationMenu from '../../components/navigation/NavigationMenu';
import { USE_NEW_AUTH } from '../../constants/env';
import { MAX_CONTENT_WIDTH } from '../../constants/layout';
import { BASE_URL_PATH, BOOKING_TOOL, LOG_IN_NEW, RIDES } from '../../constants/urlPaths';
import { MENU_STATE } from '../../constants/windowStorageKeys';
import { CurrentProfileContext } from '../../context/CurrentProfileContext';
import { isIframeView } from '../../helpers/isIframeView';
import { isMatchPath } from '../../helpers/isMatchPath';
import { ERROR_NOT_AUTHORIZED } from '../../services/routes';
import { AccountType } from '../../services/types';
import containerStyles from '../../styles/Container';
import ErrorBoundary from '../errorPages/ErrorBoundary';
import Loading from '../loading/Loading';
import { useUserProfile } from '../users/api';
import { UserRoles } from '../users/api/types';

interface Props {
    children: JSX.Element;
    mainClassName?: string;
    allowedAccountType?: AccountType[];
    allowedUserRoles?: UserRoles[];
}

export enum MenuState {
    Open = 'open',
    Closed = 'closed',
}

const PageContainer: FunctionComponent<Props> = ({ children, mainClassName, allowedAccountType, allowedUserRoles }: Props) => {
    const mobileView = window.innerWidth;
    const previousMenuState = localStorage.getItem(MENU_STATE);
    const ldClient = useLDClient();
    const location = useLocation();

    if (!previousMenuState) {
        localStorage.setItem(MENU_STATE, mobileView ? MenuState.Closed : MenuState.Open);
    }

    const classes = containerStyles();
    const history = useHistory();
    const iframeView = isIframeView(history.location.pathname);
    const currentMenuState = localStorage.getItem(MENU_STATE) === MenuState.Closed;
    const [closed, setClosed] = useState(currentMenuState);

    const { data: userProfile, refetch: refetchUserProfile, isLoading, isFetched, isFetching } = useUserProfile();

    useEffect(() => {
        if (ldClient && userProfile) {
            const ldContext = {
                kind: 'user',
                key: userProfile.email,
                name: userProfile.fullName,
                email: userProfile.email,
            };

            ldClient?.identify(ldContext);

            chameleon.identify(userProfile.id, {
                email: userProfile.email,
                name: userProfile.fullName,
                created: userProfile.joinedAt,
                role: userProfile.accountType,
            });

            setAmplitudeUserId(userProfile?.id ?? '');

            const userProperties = {
                'Account ID': userProfile.id,
                'Account Name': `${userProfile.firstName} ${userProfile.lastName}`,
                'Account Contracts': userProfile.accountType,
                'Account verticle': userProfile.agentVerticle ?? '',
                'Account tier': userProfile.agentTier ?? '',
                'Org ID': userProfile.organizationId ?? '',
                'Org name': userProfile.accountName ?? '',
                ...(userProfile.accountType === AccountType.CUSTOMER &&
                    userProfile.accountName && { 'Top-level org name': userProfile?.accountName?.split(' - ')[0] ?? '' }),
            };

            setAmplitudeUserProperties(userProperties);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    if (isLoading || isFetching) {
        return <Loading framed={false} />;
    }

    if (isFetched && !userProfile) {
        if (USE_NEW_AUTH) {
            history.push(LOG_IN_NEW);
            return null;
        }

        return <LogInMessage />;
    }

    if (userProfile && location.pathname === BASE_URL_PATH) {
        history.push(RIDES);
        return null;
    }

    if (!userProfile) {
        return <FullScreenLoader />;
    }

    const role = userProfile?.accountType ? userProfile.accountType : AccountType.CUSTOMER;

    const isBookingTool = isMatchPath(BOOKING_TOOL);

    if (allowedAccountType && !allowedAccountType.includes(role)) {
        history.push(ERROR_NOT_AUTHORIZED);
        return null;
    }

    if (allowedUserRoles && allowedUserRoles.some(userRole => !userProfile.permissions[userRole])) {
        history.push(ERROR_NOT_AUTHORIZED);
        return null;
    }

    return (
        <Container
            className={`${clsx(classes.root, {
                [classes.iframePage]: iframeView,
                [classes.rootBookingTool]: isBookingTool,
            })} ${iframeView ? 'iframePage' : ''}`}
            sx={{ maxWidth: `${MAX_CONTENT_WIDTH} !important` }}
        >
            <CurrentProfileContext.Provider
                value={{
                    currentProfile: userProfile,
                    refetchUserProfile,
                    isMenuClosed: closed,
                }}
            >
                <NavigationMenu
                    closeMenu={(): void => {
                        localStorage.setItem(MENU_STATE, MenuState.Closed);
                        setClosed(true);
                    }}
                    openMenu={(): void => {
                        localStorage.setItem(MENU_STATE, MenuState.Open);
                        setClosed(false);
                    }}
                    role={role}
                    closed={closed}
                />
                <main
                    className={clsx(
                        classes.content,
                        mainClassName,

                        { [classes.contentShift]: !closed },

                        { [classes.iframePage]: iframeView },
                        { [classes.bookingTool]: isBookingTool }
                    )}
                >
                    <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                        <Grid
                            container
                            item
                            flexDirection="row"
                            justifyContent="center"
                            xs={12}
                            // Prevents flex overlap in ie11
                            style={{ flex: '1 1 auto' }}
                        >
                            <ErrorBoundary message="reload">{children}</ErrorBoundary>
                        </Grid>
                    </Grid>
                </main>
            </CurrentProfileContext.Provider>
        </Container>
    );
};

export default PageContainer;
