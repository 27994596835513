import { AxiosResponse } from 'axios';

import apiClient from '../../../../services/api';
import {
    getCancelRideRoute,
    getSendBookingConfirmationEmailRoute,
    getUpdateFlightNumberRoute,
    getUpdateRideInformation,
    getUpdateTravellerInformation,
    getUpdateVehicleRideRoute,
} from '../../../../services/routes';
import { FlightDetailsVariables, UpdateRideInformation, UpdateTravellerInformation, UpdateVehicleRide } from '../../api/types';

export const updateRideInformation = async (variables: UpdateRideInformation): Promise<AxiosResponse> => {
    const { id, payload } = variables;
    const response = await apiClient.post(getUpdateRideInformation(id), payload);

    return response;
};

export const cancelRide = async (id: string): Promise<AxiosResponse> => {
    const response = await apiClient.post(getCancelRideRoute(id));

    return response.data as AxiosResponse;
};

export const updateTravellerInformation = async (variables: UpdateTravellerInformation): Promise<AxiosResponse> => {
    const { id, payload } = variables;
    const response = await apiClient.post(getUpdateTravellerInformation(id), { passengers: payload });

    return response;
};

export const updateFlightNumber = async (variables: FlightDetailsVariables): Promise<AxiosResponse> => {
    const { id, payload } = variables;
    const response = await apiClient.post(getUpdateFlightNumberRoute(id), payload);

    return response;
};

export const updateRideVehicle = async (variables: UpdateVehicleRide): Promise<AxiosResponse> => {
    const { carId, rideId } = variables;
    const response = await apiClient.post(getUpdateVehicleRideRoute(rideId), { uuid: carId });

    return response;
};

export const sendConfirmationEmail = async ({ id, emailTo }: { id: string; emailTo: string }): Promise<AxiosResponse> => {
    const response = await apiClient.post<AxiosResponse>(getSendBookingConfirmationEmailRoute(id), { emailTo });

    return response;
};
