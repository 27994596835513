import { Tooltip, TooltipProps, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

interface CustomWidthTooltipProps extends TooltipProps {
    maxWidth?: number;
}

export const CustomWidthTooltip = styled(({ className, maxWidth, ...props }: CustomWidthTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: ({ maxWidth }: CustomWidthTooltipProps) => maxWidth } });
