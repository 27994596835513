import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import {
    SINGLE_RIDE_ADD_REMARKS_SAVE,
    SINGLE_RIDE_CLOSE_SAVE,
    SINGLE_RIDE_CONFIRM_SAVE,
} from '../../../../constants/amplitude/supplierKeys';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import { RemarksValues } from '../../../rides/supplier/components/RemarksCard';
import { addRideRemarks, closeRide, confirmRide } from '../../api';
import { RideResponse } from '../../api/types';
import { RideContext } from '../context/RideContext';
import { remarksValuesToConfirmCloseAddRemarksRideRequest } from '../helpers';

interface UseConformCloseAddRemarksRide {
    onConfirmRide: () => Promise<RideResponse | undefined>;
    onCloseRide: () => Promise<RideResponse | undefined>;
    onAddRideRemarks: () => Promise<RideResponse | undefined>;
    isSaving: boolean;
}

interface UseConfirmCloseAddRemarksRideProps {
    rideId: string;
    reference?: string | null;
    remarksValues: RemarksValues;
}

const useConfirmCloseAddRemarksRide = ({
    rideId,
    reference,
    remarksValues,
}: UseConfirmCloseAddRemarksRideProps): UseConformCloseAddRemarksRide => {
    const [isSaving, setIsSaving] = useState(false);
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const { amplitudeEventProperties } = useContext(RideContext);

    const onConfirmRide = async () => {
        const request = remarksValuesToConfirmCloseAddRemarksRideRequest(reference ?? null, remarksValues);

        try {
            setIsSaving(true);
            const { data } = await confirmRide(rideId, request);

            showNotification(t('pages.singleRide.confirmRideSuccessNotification'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_CONFIRM_SAVE, amplitudeEventProperties);
            return data;
        } catch (error) {
            showNotification(t('pages.singleRide.confirmRideFailedNotification'), Severity.Error);
        } finally {
            setIsSaving(false);
        }
    };

    const onCloseRide = async () => {
        const request = remarksValuesToConfirmCloseAddRemarksRideRequest(reference ?? null, remarksValues);

        try {
            setIsSaving(true);
            const { data } = await closeRide(rideId, request);

            showNotification(t('pages.singleRide.closeRideSuccessNotification'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_CLOSE_SAVE, amplitudeEventProperties);
            return data;
        } catch (error) {
            showNotification(t('pages.singleRide.closeRideFailedNotification'), Severity.Error);
        } finally {
            setIsSaving(false);
        }
    };

    const onAddRideRemarks = async () => {
        const request = remarksValuesToConfirmCloseAddRemarksRideRequest(reference ?? null, remarksValues);

        try {
            setIsSaving(true);
            const { data } = await addRideRemarks(rideId, request);

            showNotification(t('pages.singleRide.addRemarksSuccessNotification'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS_SAVE, amplitudeEventProperties);
            setIsSaving(false);
            return data;
        } catch (error) {
            showNotification(t('pages.singleRide.addRemarksFailedNotification'), Severity.Error);
            setIsSaving(false);
        }
    };

    return {
        onConfirmRide,
        onCloseRide,
        onAddRideRemarks,
        isSaving,
    };
};

export default useConfirmCloseAddRemarksRide;
