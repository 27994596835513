/* eslint-disable max-statements */
import { FullScreenLoader, Select, SelectOption, Tab, Tabs } from '@get-e/react-components';
import { Chip, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addDays, addMonths, endOfMonth, startOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PERIODS } from '../../constants';
import { COLORS } from '../../constants/colors';
import { DATE_FORMATS } from '../../constants/dateFormats';
import { datePickerPlaceholder } from '../../constants/datePickerPlaceholder';
import { USE_REPORT_CURRENT_PERIOD, USE_REPORT_PREVIOUS_PERIOD } from '../../constants/queryKeys';
import { REPORTS, REPORTS_DRIVER_ANALYSIS, REPORTS_REVIEWS, REPORTS_SITUATIONS, REPORTS_SUMMARY } from '../../constants/urlPaths';
import { useCurrentProfileContext } from '../../context/CurrentProfileContext';
import { AccountTypeAbbreviation } from '../../services/types';
import theme from '../../styles/theme';
import { useReport } from './components/api/useReport';
import ReportsLoadingSkeleton from './components/ReportsLoadingSkeleton';
import DriverAnalysisTab from './components/tabs/DriverAnalysisTab';
import ReviewsTab from './components/tabs/ReviewsTab';
import SituationsTab from './components/tabs/SituationsTab';
import SummaryTab from './components/tabs/SummaryTab';

const useStyles = makeStyles({
    mainContainer: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0 2rem 2rem 2rem',
    },
    mainContainerMobile: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '1rem',
    },
    pageHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: COLORS.BLACK,
    },
    pageHeader: {
        color: COLORS.BLUE,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginRight: '2rem',
    },
});

export enum ReportsActiveTab {
    Summary,
    Situations,
    DriverAnalysis,
    Reviews,
}

const getActiveTab = (url: string): ReportsActiveTab => {
    switch (url) {
        case REPORTS:
        case REPORTS_SUMMARY:
            return ReportsActiveTab.Summary;
        case REPORTS_SITUATIONS:
            return ReportsActiveTab.Situations;
        case REPORTS_DRIVER_ANALYSIS:
            return ReportsActiveTab.DriverAnalysis;
        case REPORTS_REVIEWS:
            return ReportsActiveTab.Reviews;
        default:
            throw new Error(`Unhandled URL "${url}"`);
    }
};

const dateNow = new Date();
const yesterday = addDays(dateNow, -1);

const Reports = () => {
    const history = useHistory();
    const classes = useStyles();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
    const { pathname } = history.location;
    const { t } = useTranslation();
    const [period, setPeriod] = useState<number | null>(PERIODS.LAST_30_DAYS.value);
    const [month, setMonth] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState(addDays(yesterday, -PERIODS.LAST_30_DAYS.value));
    const [endDate, setEndDate] = useState(yesterday);
    const [openDate, setOpenDate] = useState(false);
    const { currentProfile: currentUser } = useCurrentProfileContext();

    const [previousStartDate, setPreviousStartDate] = useState(addDays(yesterday, -PERIODS.LAST_30_DAYS.value * 2));

    const [previousEndDate, setPreviousEndDate] = useState(addDays(yesterday, -PERIODS.LAST_30_DAYS.value));

    const activeTab = useMemo(() => getActiveTab(pathname), [pathname]);

    const handlePeriods = (newValue: number | null) => {
        setMonth(null);
        setPeriod(newValue);

        if (newValue === null || isLoading) {
            return;
        }

        setStartDate(addDays(yesterday, -newValue));
        setEndDate(yesterday);
        setPreviousStartDate(addDays(yesterday, -newValue * 2));
        setPreviousEndDate(addDays(yesterday, -newValue));
    };

    const handleMonth = (newValue: Date | null) => {
        setPeriod(null);

        setMonth(newValue);

        if (newValue) {
            setStartDate(startOfMonth(newValue));
            setEndDate(endOfMonth(newValue) > dateNow ? dateNow : endOfMonth(newValue));
            setPreviousStartDate(addMonths(startOfMonth(newValue), -1));
            setPreviousEndDate(addMonths(endOfMonth(newValue), -1));
        }
    };

    const endDateCurrentMonth = endDate > dateNow ? dateNow : endDate;
    const dayjsStartDate = dayjs(startDate).startOf('day');
    const dayjsEndDate = dayjs(endDate).endOf('day');
    const dayjsEndDateCurrentMonth = dayjs(endDateCurrentMonth).endOf('day');
    const dayjsPreviousStartDate = dayjs(previousStartDate).startOf('day');
    const dayjsPreviousEndDate = dayjs(previousEndDate).endOf('day');

    const {
        report: currentPeriod,
        refetchAll: refetchCurrent,
        isLoading: isLoadingCurrent,
    } = useReport({
        startDate: dayjsStartDate.format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
        endDate: dayjsEndDate.format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
        accountId: currentUser.accountId,
        accountType: AccountTypeAbbreviation[currentUser.accountType],
        queryKey: USE_REPORT_CURRENT_PERIOD + startDate + endDate,
    });

    const {
        report: pastPeriod,
        refetchAll: refetchPast,
        isLoading: isLoadingPast,
    } = useReport({
        startDate: dayjsPreviousStartDate.format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
        endDate: dayjsPreviousEndDate.format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
        accountId: currentUser.accountId,
        accountType: AccountTypeAbbreviation[currentUser.accountType],
        queryKey: USE_REPORT_PREVIOUS_PERIOD + previousStartDate + previousEndDate,
    });

    useEffect(() => {
        refetchCurrent();
        refetchPast();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, month]);

    const previousPeriod = useMemo(
        () =>
            `${dayjsPreviousStartDate.format(DATE_FORMATS['DD MMM'])}
         - ${dayjsPreviousEndDate.format(DATE_FORMATS['DD MMM'])}`,
        [dayjsPreviousStartDate, dayjsPreviousEndDate]
    );

    const currentPeriodDates = useMemo(
        () =>
            `${dayjsStartDate.format(DATE_FORMATS['DD MMM'])}
          - ${dayjsEndDateCurrentMonth.format(DATE_FORMATS['DD MMM'])}`,
        [dayjsStartDate, dayjsEndDateCurrentMonth]
    );

    const isLoading = useMemo(() => isLoadingCurrent || isLoadingPast, [isLoadingCurrent, isLoadingPast]);

    if (isLoading) {
        return <ReportsLoadingSkeleton />;
    }

    return (
        <Grid container className={isSmallDevice ? classes.mainContainerMobile : classes.mainContainer}>
            <Grid container className={classes.pageHeaderWrapper}>
                <Grid item xs display="flex" flexDirection="row" alignItems="center" padding={isSmallDevice ? '1rem 0 0 0' : ''}>
                    <Typography className={classes.pageHeader}>{t('pages.reports.qualityReport')}</Typography>
                    <Chip label={currentPeriodDates} variant="outlined" sx={{ fontFamily: 'Open Sans' }} />
                </Grid>
                <Grid container marginTop="2rem">
                    <Grid item xs={12} md={3} xl={3}>
                        <Select
                            label={t('selectPeriod')}
                            value={period}
                            onChangeValue={newValue => handlePeriods(newValue)}
                            disabled={isLoading}
                            sx={{ width: '99%' }}
                        >
                            <SelectOption value={PERIODS.LAST_30_DAYS.value}>{PERIODS.LAST_30_DAYS.label}</SelectOption>
                            <SelectOption value={PERIODS.LAST_7_DAYS.value}>{PERIODS.LAST_7_DAYS.label}</SelectOption>
                            <SelectOption value={PERIODS.YESTERDAY.value}>{PERIODS.YESTERDAY.label}</SelectOption>
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={3} xl={3} marginLeft={isSmallDevice ? 0 : '2rem'}>
                        <DatePicker
                            views={['month', 'year']}
                            label={t('selectMonth')}
                            format={DATE_FORMATS.MMMM}
                            value={month}
                            onChange={handleMonth}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    onClick: () => setOpenDate(true),
                                },
                            }}
                            localeText={datePickerPlaceholder}
                            onClose={() => setOpenDate(false)}
                            open={openDate}
                            disabled={isLoading}
                            maxDate={dateNow}
                            minDate={addDays(dateNow, -3472)} // Days since 2015
                            sx={{ width: '99%' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Tabs value={activeTab}>
                    <Tab label={t('summary')} url={REPORTS_SUMMARY} index={ReportsActiveTab.Summary} selectedIndex={activeTab} />
                    <Tab
                        label={t('pages.reports.situations')}
                        url={REPORTS_SITUATIONS}
                        index={ReportsActiveTab.Situations}
                        selectedIndex={activeTab}
                    />
                    <Tab
                        label={t('pages.reports.driverAnalysis')}
                        url={REPORTS_DRIVER_ANALYSIS}
                        index={ReportsActiveTab.DriverAnalysis}
                        selectedIndex={activeTab}
                    />
                    <Tab label={t('reviews')} url={REPORTS_REVIEWS} index={ReportsActiveTab.Reviews} selectedIndex={activeTab} />
                </Tabs>
            </Grid>
            <Grid container width={'100%'}>
                {isLoading ? (
                    <Grid item xs sx={{ '& > div > div': { minHeight: 'calc(100vh - 300px)' } }}>
                        <FullScreenLoader />
                    </Grid>
                ) : (
                    <>
                        {activeTab === ReportsActiveTab.Summary ? (
                            <Grid item xs={12} width="100%">
                                <SummaryTab currentPeriod={currentPeriod} pastPeriod={pastPeriod} period={previousPeriod} />
                            </Grid>
                        ) : null}
                        {activeTab === ReportsActiveTab.Situations ? (
                            <Grid item xs={12}>
                                <SituationsTab currentPeriod={currentPeriod} pastPeriod={pastPeriod} period={previousPeriod} />
                            </Grid>
                        ) : null}
                        {activeTab === ReportsActiveTab.Reviews ? (
                            <Grid item xs={12}>
                                <ReviewsTab currentPeriod={currentPeriod} pastPeriod={pastPeriod} period={previousPeriod} />
                            </Grid>
                        ) : null}
                        {activeTab === ReportsActiveTab.DriverAnalysis ? (
                            <Grid item xs={12} width="100%">
                                <DriverAnalysisTab
                                    currentPeriod={currentPeriod}
                                    pastPeriod={pastPeriod}
                                    period={previousPeriod}
                                />
                            </Grid>
                        ) : null}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Reports;
