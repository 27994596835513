import { AxiosResponse } from 'axios';

import apiClient from '../../../services/api';
import { FORGOT_PASSWORD } from '../../../services/routes';
import { ForgotPasswordRequest } from './types';

export const forgotPassword = async (request: ForgotPasswordRequest): Promise<AxiosResponse> => {
    const response = await apiClient.post<ForgotPasswordRequest>(FORGOT_PASSWORD, request);

    return response;
};
