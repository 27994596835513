import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { COLORS } from '../../../constants/colors';

const Heading = ({ children }: PropsWithChildren) => {
    return (
        <Typography
            style={{
                color: COLORS.BLUE,
                fontSize: '1.5rem',
                fontWeight: 700,
                paddingRight: '1rem',
                marginBottom: '2rem',
            }}
            component="h1"
        >
            {children}
        </Typography>
    );
};

export default Heading;
