import { useQuery } from 'react-query';

import { USE_FLIGHT } from '../../../../constants/queryKeys';
import apiClient from '../../../../services/api';
import { getFlightInfoRoute } from '../../../../services/routes';
import { FlightDetailsNew } from '../../../rides/api/types';

export function useFlight(rideId: string) {
    const {
        data: flightDetails,
        isLoading,
        isError,
        refetch,
    } = useQuery([USE_FLIGHT + rideId], () => getFlightInfo(rideId), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
    });

    return {
        flightDetails,
        isLoading,
        isError,
        refetch,
    };
}

export const getFlightInfo = async (id: string): Promise<FlightDetailsNew> => {
    const { data } = await apiClient.get<FlightDetailsNew>(getFlightInfoRoute(id));

    return data;
};
