export const UK_CALL_NUMBER = '+44 203 856 8655';
export const PERU_CALL_NUMBER = '+51 1 706 1737';
export const CHILE_CALL_NUMBER = '+56 2 32411980';

export const UK_GLOBAL_OPERATIONS = '+44 20 3856 8655';
export const NL_OPERATIONS = '+31 85 8884 450';

export const EUROPE_PRIORITY = '+353 1 223 8865';
export const GLOBAL_PRIORITY = '+1 844 960 2850';

export const OPERATIONS_EMAIL = 'operations@get-e.com';

export const WEB_WHATSAPP_REDIRECTING = 'https://wa.me/';
