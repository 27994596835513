import { AxiosResponse } from 'axios';

import apiClient from '../../../../services/api';
import { getAssignDriverToRide, getUnassignDriverFromRide } from '../../../../services/routes';
import { RideResponse } from '../../../ride/api/types';
import { AssignDriverToRide } from './types';

export const assignDriverToRide = async (variables: AssignDriverToRide): Promise<AxiosResponse<RideResponse>> => {
    const response = await apiClient.post(getAssignDriverToRide(variables));

    return response;
};

export const unassignDriverFromRide = async (rideId: string): Promise<AxiosResponse<RideResponse>> => {
    const response = await apiClient.post(getUnassignDriverFromRide(rideId));

    return response;
};
