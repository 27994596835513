/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';

import GoogleMap from '../../../../components/navigation/GoogleMap';
import { COLORS } from '../../../../constants/colors';
import { RideResponse } from '../../api/types';
import CancellationPoliciesCard from '../../components/CancellationPoliciesCard';
import FlightInformationCard from '../../customer/components/FlightInformationCard';
import PassengerInformationCard from '../../customer/components/PassengerInformationCard';
import RideInformationCard from '../../customer/components/RideInformationCard';
import DriverReferencesCard from '../components/DriverReferencesCard';
import SelectedVehicleCard from '../components/SelectedVehicleCard';

export const renderDesktopView = (data: RideResponse | undefined | any, classes: any, t: any, refetch: () => void) => {
    return (
        <>
            <Grid xs={12} md={12} lg={7.5} item marginTop="1rem">
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <RideInformationCard isSupplierPage data={data} showUtcSwitch={false} />
                </Grid>
                {data?.vehicle && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <SelectedVehicleCard data={data} />
                    </Grid>
                )}
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <FlightInformationCard rideId={data?.unid || ''} showUtcSwitch={false} />
                </Grid>
                {Boolean(data?.passengers?.length) && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <PassengerInformationCard data={data} showClientInstructions />
                    </Grid>
                )}
                {Boolean(data?.cancellationPolicies?.length) && (
                    <Grid
                        item
                        xs
                        display="flex"
                        justifyItems="flex-start"
                        className={clsx(classes.contentContainer, classes.cancellationPolicies)}
                    >
                        <CancellationPoliciesCard
                            isUtc
                            isVisible
                            cancelledAt={data?.cancelledAt || null}
                            cancellationPolicies={data?.cancellationPolicies ?? []}
                            status={data?.status || null}
                            pickUpDate={dayjs(data?.pickUp?.departAt).toDate()}
                            pickUpTime={dayjs(data?.pickUp?.departAt).toDate()}
                            vehicleType={data?.vehicle.type}
                            departureTimeZone={data?.pickUp.departureTimeZone}
                            appliedCancellationPolicy={data?.appliedCancellationPolicy}
                            order={3}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={4.5} width="100%" marginTop="1rem">
                <Grid
                    className={clsx(classes.contentContainer, classes.googleMapContainer)}
                    sx={{
                        marginLeft: '2rem',
                        paddingLeft: '0 !important',
                    }}
                >
                    <GoogleMap
                        points={[data?.pickUp?.location?.coordinates, data?.dropOff?.location?.coordinates]}
                        placeIds={[data?.pickUp?.location?.id ?? '', data?.dropOff.location.id ?? '']}
                        subscribeChannelId={`private-trip-${data?.prettifiedUnid}`}
                        driverPosition={data?.lastPositionUpdate}
                    />
                    <Grid className={classes.priceOnMap}>
                        <Grid>{t('price')}:</Grid>
                        <Typography
                            variant="h5"
                            style={{
                                color: COLORS.ORANGE,
                                fontWeight: 700,
                                fontSize: '1rem',
                                marginLeft: '1rem',
                            }}
                        >
                            {data?.vehicle?.priceCurrency ? `${data.vehicle?.priceCurrency} ` : `${data?.price?.isoCurrency} `}
                            {data?.vehicle?.price}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <DriverReferencesCard data={data} refetch={refetch} />
                </Grid>
            </Grid>
        </>
    );
};

export const renderMobileView = (data: RideResponse | undefined | any, classes: any, t: any, refetch: () => void) => {
    return (
        <>
            <Grid item xs>
                <Grid className={clsx(classes.contentContainer, classes.googleMapContainerMobile)}>
                    <GoogleMap
                        points={[data?.pickUp?.location?.coordinates, data?.dropOff?.location?.coordinates]}
                        placeIds={[data?.pickUp?.location?.id ?? '', data?.dropOff.location.id ?? '']}
                        subscribeChannelId={`private-trip-${data?.prettifiedUnid}`}
                        driverPosition={data?.lastPositionUpdate}
                    />
                    <Grid className={classes.priceOnMap}>
                        <Grid>{t('price')}:</Grid>
                        <Typography
                            variant="h5"
                            style={{
                                color: COLORS.ORANGE,
                                fontWeight: 700,
                                fontSize: '1rem',
                                marginLeft: '1rem',
                            }}
                        >
                            {data?.vehicle?.priceCurrency ? `${data.vehicle?.priceCurrency} ` : `${data?.price?.isoCurrency} `}
                            {data?.vehicle?.price}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <RideInformationCard isSupplierPage data={data} showUtcSwitch={false} />
                </Grid>
                {data?.vehicle && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <SelectedVehicleCard data={data} />
                    </Grid>
                )}
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <FlightInformationCard rideId={data?.unid || ''} showUtcSwitch={false} />
                </Grid>
                {Boolean(data?.passengers?.length) && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <PassengerInformationCard data={data} showClientInstructions />
                    </Grid>
                )}
                {Boolean(data?.cancellationPolicies?.length) && (
                    <Grid
                        item
                        xs
                        display="flex"
                        justifyItems="flex-start"
                        className={clsx(classes.contentContainer, classes.cancellationPolicies)}
                    >
                        <CancellationPoliciesCard
                            isUtc
                            isVisible
                            cancelledAt={data?.cancelledAt || null}
                            cancellationPolicies={data?.cancellationPolicies ?? []}
                            status={data?.status || null}
                            pickUpDate={dayjs(data?.pickUp?.departAt).toDate()}
                            pickUpTime={dayjs(data?.pickUp?.departAt).toDate()}
                            vehicleType={data?.vehicle.type}
                            departureTimeZone={data?.pickUp.departureTimeZone}
                            appliedCancellationPolicy={data?.appliedCancellationPolicy}
                            order={3}
                        />
                    </Grid>
                )}
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <DriverReferencesCard data={data} refetch={refetch} />
                </Grid>
            </Grid>
        </>
    );
};
