export const getPercentage = (part: number, total: number, decimals = 2) => {
    if (part === 0 || total === 0) {
        return Number(0).toFixed(decimals);
    }

    const percentage = (part / total) * 100;

    if (percentage <= 0.01) {
        return '0.01';
    }

    return percentage.toFixed(decimals);
};
