import { PrimaryButton, SignedOutLayout, TextField } from '@get-e/react-components';
import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import uuid from 'react-uuid';

import ShowPasswordButton from '../../components/buttons/showPasswordButton/ShowPasswordButton';
import { COLORS } from '../../constants/colors';
import { LOG_IN } from '../../constants/urlPaths';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import { InputError } from '../../helpers/inputValidation/InputError';
import allValid from '../../helpers/inputValidation/validators/allValid';
import isEqualString from '../../helpers/inputValidation/validators/isEqualString';
import isPasswordStrong from '../../helpers/inputValidation/validators/isPasswordStrong';
import useEmailFromUrlQuery from '../../hooks/useEmailFromUrlQuery';
import useUrlQuery from '../../hooks/useUrlQuery';
import { logoIconLightBackground } from '../../public/assets/images';
import theme from '../../styles/theme';
import { resetPassword } from './api';

const useStyles = makeStyles({
    container: { minHeight: '100%' },
    formField: { marginBottom: '2rem' },
    buttons: { width: '100%' },
    signInButton: {
        width: '100%',
        marginTop: '2rem',
    },
    logoContainer: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 110,
        marginBottom: '2rem',
    },
    helpText: { fontSize: '0.75rem' },
});

const TOKEN_QUERY_PARAMETER = 'token';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<InputError | null>(null);
    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const urlParams = useUrlQuery();
    const token = urlParams.get(TOKEN_QUERY_PARAMETER) ?? '';
    const { showNotification } = useNotificationContext();
    const history = useHistory();
    const email = useEmailFromUrlQuery();

    const { mutate: resetPasswordMutation, isLoading } = useMutation(resetPassword, {
        onSuccess: () => {
            showNotification(t('pages.resetPassword.resetPasswordSuccessfull'), Severity.Info);
            history.push(LOG_IN);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
            showNotification(error?.response?.data?.message || t('errors.defaultError'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        setPasswordError(null);
        setConfirmPasswordError(null);

        const validated = {
            password: isPasswordStrong(password, InputError.NotStrongPassword),
            confirmPassword: isEqualString(confirmPassword, password, InputError.NoMatch),
        };

        if (!allValid(validated)) {
            setPasswordError(validated.password.isValid ? null : validated.password.error);
            setConfirmPasswordError(validated.confirmPassword.isValid ? null : validated.confirmPassword.error);

            return false;
        }

        return true;
    };

    const handleUpdate = () => {
        if (validateFormFields()) {
            resetPasswordMutation({
                password,
                password_confirmation: confirmPassword,
                token,
                email,
            });
        }
    };

    return (
        <SignedOutLayout linkPrefix={t('visit').toLowerCase()}>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
                <Card variant="outlined" sx={{ width: isMobile ? '100%' : '500px' }}>
                    <CardContent sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <img src={logoIconLightBackground} alt={'Logo'} className={classes.logoContainer} />
                        </Grid>
                        <Typography variant="h2" component="h2" color={COLORS.BLUE_TEXT}>
                            {t('pages.resetPassword.resetYourPassword')}
                        </Typography>
                        <Typography sx={{ marginBottom: '2rem' }}>{t('pages.resetPassword.pleaseEnterNewPassword')}</Typography>
                        <TextField
                            id={uuid()}
                            label={t('password')}
                            type={showingPassword ? 'text' : 'password'}
                            value={password}
                            onChange={event => {
                                setPassword(event.target.value);
                                setPasswordError(null);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <ShowPasswordButton
                                        onShowPassword={isPasswordVisible => {
                                            setShowingPassword(isPasswordVisible);
                                        }}
                                    />
                                ),
                            }}
                            error={passwordError !== null}
                            helperText={passwordError ? t(passwordError) : undefined}
                            required
                            className={classes.formField}
                        />
                        <TextField
                            id={uuid()}
                            label={t('form.fields.confirmPassword')}
                            type={showingConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={event => {
                                setConfirmPassword(event.target.value);
                                setConfirmPasswordError(null);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <ShowPasswordButton
                                        onShowPassword={isPasswordVisible => {
                                            setShowingConfirmPassword(isPasswordVisible);
                                        }}
                                    />
                                ),
                            }}
                            error={confirmPasswordError !== null}
                            helperText={confirmPasswordError ? t(confirmPasswordError) : undefined}
                            required
                            className={classes.formField}
                        />
                        <input name="token" type="hidden" value={token} />
                        <input name="email" type="hidden" value={email} />
                        <Typography variant="body1" mb={'2em'} className={classes.helpText}>
                            {t('useEightOrMoreCharacters')}
                        </Typography>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <PrimaryButton onClick={handleUpdate} className={classes.buttons} loading={isLoading}>
                                {t('buttonName.updatePassword')}
                            </PrimaryButton>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </SignedOutLayout>
    );
};

export default ResetPassword;
