export const USE_STOP_SALES_RULE = 'use-stop-sales-rule';
export const USE_STOP_SALES_RULES = 'use-stop-sales-rules';
export const USE_PAST_STOP_SALES_RULES = 'use-past-stop-sales-rules';
export const USE_RIDES = 'use-rides';
export const USE_USER_PROFILE = 'use-user-profile';

export const USE_RIDE = 'use-ride';
export const USE_AVAILABILITIES = 'use-availabilities';
export const USE_SEARCH_LOCATION = 'use-search-location';
export const USE_FLIGHT_SEARCH = 'use-flight-search';
export const USE_FLIGHT = 'use-flight';

export const USE_DRIVERS = 'use-drivers';
export const USE_DRIVER = 'use-driver';

export const USE_VEHICLES = 'use-vehicles';
export const USE_BRANDS = 'use-brands';
export const USE_VEHICLE = 'use-vehicle';
export const USE_MODELS = 'use-models';
export const USE_IMAGES = 'use-images';
export const USE_DRIVER_VEHICLES = 'use-driver-vehicles';
export const USE_RIDE_VEHICLE = 'use-ride-vehicle';

export const USE_USERS = 'use-users';
export const USE_ACTIVE_USERS = 'use-active-users';
export const USE_DEACTIVE_USERS = 'use-deactive-users';
export const USE_INVITED_USERS = 'use-invited-users';

export const USE_DOCUMENTS = 'use-documents';
export const USE_FILES = 'use-files';

export const USE_SUPPLIER_INVOICES = 'use-supplier-invoices';

export const USE_REPORT_CURRENT_PERIOD = 'use-report-current-period';
export const USE_REPORT_PREVIOUS_PERIOD = 'use-report-previous-period';

export const USE_BOOKING_TOOL_URL = 'use-booking-tool-url';
