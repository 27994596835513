import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

export const shortcutsItems: Array<{ label: string; getValue: () => DateRange<Dayjs | null> }> = [
    {
        label: 'All time',
        getValue: () => [null, null],
    },
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();

            return [today.startOf('week'), today.endOf('week')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'This Month',
        getValue: () => {
            const today = dayjs();

            return [today.startOf('month'), today.endOf('month')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'This Year',
        getValue: () => {
            const today = dayjs();

            return [today.startOf('year'), today.endOf('year')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(1, 'week');

            return [prevWeek.startOf('week'), prevWeek.endOf('week')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last Month',
        getValue: () => {
            const today = dayjs();
            const prevMonth = today.subtract(1, 'month');

            return [prevMonth.startOf('month'), prevMonth.endOf('month')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last Year',
        getValue: () => {
            const today = dayjs();
            const prevYear = today.subtract(1, 'year');

            return [prevYear.startOf('year'), prevYear.endOf('year')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();

            return [today.subtract(7, 'day'), today] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last 14 Days',
        getValue: () => {
            const today = dayjs();

            return [today.subtract(14, 'day'), today] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last 30 Days',
        getValue: () => {
            const today = dayjs();

            return [today.subtract(30, 'day'), today] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last 60 Days',
        getValue: () => {
            const today = dayjs();

            return [today.subtract(60, 'day'), today] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last 90 Days',
        getValue: () => {
            const today = dayjs();

            return [today.subtract(90, 'day'), today] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Last 12 Months',
        getValue: () => {
            const today = dayjs();
            const startOfLastYear = today.subtract(12, 'month').startOf('month');

            return [startOfLastYear, today] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Next Month',
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');

            return [startOfNextMonth, startOfNextMonth.endOf('month')] as DateRange<Dayjs>;
        },
    },
    {
        label: 'Reset',
        getValue: () => [null, null],
    },
];
