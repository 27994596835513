import CameraAltIcon from '@mui/icons-material/CameraAlt';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { COLORS } from '../../../constants/colors';
import LightBoxPortal from './LightBoxPortal';

const useStyles = makeStyles({
    vehicleBox: (props: { boxSize?: string }) => ({
        height: props.boxSize ?? 'auto',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '36px',
        borderRadius: '4px',
    }),
    cameraBox: (props: { boxSize?: string }) => ({
        height: props.boxSize || 'auto',
        minWidth: props.boxSize || 'auto',
        borderRadius: '4px',
        backgroundColor: COLORS.LIGHT_GRAY,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }),
    photoBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '36px',
        height: '36px',
    },
});

const SmallVehicleImage = ({ photo, boxSize }: { photo?: string; boxSize?: string }) => {
    const classes = useStyles({ boxSize });
    const [isViewerOpen, setViewerOpen] = useState(false);

    const handlePhotoOpen = evt => {
        setViewerOpen(true);
        evt.stopPropagation();
    };

    const handlePhotoClose = evt => {
        setViewerOpen(false);
        evt.stopPropagation();
    };

    if (!photo) {
        return (
            <Box className={classes.cameraBox}>
                <CameraAltIcon style={{ color: COLORS.SLATE_GREY }} />
            </Box>
        );
    }

    return (
        <Box className={classes.photoBox}>
            <img
                onClick={handlePhotoOpen}
                src={photo}
                alt="Vehicle"
                className={classes.vehicleBox}
                style={{ objectFit: 'cover', backgroundPosition: '0 0' }}
            />
            {<LightBoxPortal open={isViewerOpen} onClose={handlePhotoClose} images={[photo]} />}
        </Box>
    );
};

export default SmallVehicleImage;
