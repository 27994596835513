import { TextField } from '@get-e/react-components';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';

interface AdditionalCostsFormProps {
    additionalCostsValues: {
        extraWaitingTime: string | null;
        waitingTimeCosts: string | null;
        parkingTollCosts: string | null;
        otherCosts: string | null;
        comments: string | null;
    };
    onChangeField: (field: string, value: string) => void;
}

const AdditionalCostsForm = ({ additionalCostsValues, onChangeField }: AdditionalCostsFormProps) => {
    const { t } = useTranslation();

    return (
        <Box paddingBottom="1rem">
            <Typography
                style={{
                    fontWeight: 700,
                    color: COLORS.BLUE_DARK,
                    marginBottom: '1rem',
                }}
            >
                {t('modals.referencesAndRemarks.additionalCosts')}
            </Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    color: COLORS.SLATE_GREY,
                    marginBottom: '1rem',
                }}
            >
                {t('modals.referencesAndRemarks.additionalCostsDescription1')}
            </Typography>
            <Typography
                style={{
                    fontWeight: 400,
                    color: COLORS.BLACK,
                    marginBottom: '1rem',
                }}
            >
                {t('modals.referencesAndRemarks.additionalCostsDescription2')}
            </Typography>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                }}
            >
                <Grid item xs={12} md={6}>
                    <TextField
                        type="number"
                        label={t('modals.referencesAndRemarks.extraWaitingTime')}
                        value={additionalCostsValues.extraWaitingTime}
                        onChange={event => onChangeField('extraWaitingTime', event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="number"
                        label={t('modals.referencesAndRemarks.waitingTimeCosts')}
                        value={additionalCostsValues.waitingTimeCosts}
                        onChange={event => onChangeField('waitingTimeCosts', event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="number"
                        label={t('modals.referencesAndRemarks.parkingTollCosts')}
                        value={additionalCostsValues.parkingTollCosts}
                        onChange={event => onChangeField('parkingTollCosts', event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="number"
                        label={t('modals.referencesAndRemarks.otherCosts')}
                        value={additionalCostsValues.otherCosts}
                        onChange={event => onChangeField('otherCosts', event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('comments')}
                        value={additionalCostsValues.comments}
                        onChange={event => onChangeField('comments', event.target.value)}
                        InputProps={{
                            minRows: '3',
                            multiline: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdditionalCostsForm;
