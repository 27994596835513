/* eslint-disable id-length */
import { useQuery } from 'react-query';

import { USE_SEARCH_LOCATION } from '../../../../constants/queryKeys';
import apiClient from '../../../../services/api';
import { SEARCH_LOCATION } from '../../../../services/routes';
import { SearchLocation } from '../../api/types';

export function useSearchLocation(search: string, isSearchEnabled?: boolean) {
    const {
        data: searchOptions,
        isLoading,
        refetch,
    } = useQuery([USE_SEARCH_LOCATION + search], () => listSearchLocationOptions(search), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
        enabled: Boolean(isSearchEnabled),
    });

    return {
        searchOptions,
        isLoading,
        refetch,
    };
}

export const listSearchLocationOptions = async (search: string): Promise<SearchLocation[]> => {
    const { data } = await apiClient.get<SearchLocation[]>(SEARCH_LOCATION, { params: { q: search } });

    return data;
};
