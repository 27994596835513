import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import React from 'react';

import { COLORS } from '../../../constants/colors';
import { QualityValuationEnum } from '../components/api/types';

export const getStatusColor = (status: QualityValuationEnum) => {
    switch (status) {
        case QualityValuationEnum.ACCURATE:
            return COLORS.GREEN;
        case QualityValuationEnum.NO_UPDATES:
            return COLORS.ORANGE_OFFICIAL;
        case QualityValuationEnum.NOT_ACCURATE:
            return COLORS.RED;
        default:
            throw new Error('Driver update or location sharing status not supported');
    }
};

export const getStatusIcon = (status: QualityValuationEnum) => {
    switch (status) {
        case QualityValuationEnum.ACCURATE:
            return (
                <MoodOutlinedIcon
                    style={{
                        color: getStatusColor(status),
                        marginRight: '0.5rem',
                    }}
                />
            );
        case QualityValuationEnum.NO_UPDATES:
            return (
                <SentimentNeutralOutlinedIcon
                    style={{
                        color: getStatusColor(status),
                        marginRight: '0.5rem',
                    }}
                />
            );
        case QualityValuationEnum.NOT_ACCURATE:
            return (
                <MoodBadOutlinedIcon
                    style={{
                        color: getStatusColor(status),
                        marginRight: '0.5rem',
                    }}
                />
            );
        default:
            throw new Error('Driver update or location sharing status not supported');
    }
};
